import React, { useState } from "react";

const URL_REGEX =/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
const INPUT_REGEX =/^[\w.-]+/

export default function UrlInput({ title, data, setData}) {
  const [error, setError] = useState(false)
  const [input, setInput] = useState(data)

  // console.log(input)
  const handleChange = (e) => {
    let value = e.target.value
    setInput(value)
  };

  const handleBlur = (e) => {
    let value = e.target.value
    if(value.match(URL_REGEX)){
      setData(value)
      setError(false)
    }
    else 
      setError(true)
  }

  return (
    <div className="block">
      <div className={`py-1 my-2 title-3`}>{title}</div>
      <input
        className="input"
        value={input}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {error &&
          <label className='block text-s bg-light dark:bg-dark md:w-96 px-3 py-2 text-error dark:text-error-dark'>
            Unothorized character
          </label>
      }
    </div>
  );
}
