import React from "react";
import QRTypeCard from "./QRTypeCard";
import {WEBSITE, VCARD, MENU, WIFI, CV, SPA, LOCATION, PDF, VFLYER} from '../features/targetTypes'

import { useState } from "react";
// import "./css/analysis.css";

const qrTypes = [
  {isActive:true, title:'Site Web', subtitle:'Ouvrez une URL', targetType:WEBSITE},
  {isActive:true, title:'PDF', subtitle:'Partagez des fichiers PDF', targetType:PDF},
  {isActive:false, title:'vCard', subtitle:'Carte de visite virtuelle', targetType:VCARD},
  {isActive:false, title:'Menu', subtitle:'Accès sans contact au menu', targetType:MENU},
  {isActive:false, title:'Wifi', subtitle:'Code wifi', targetType:WIFI},
  {isActive:false, title:'CV', subtitle:'Votre CV au bout du doigt', targetType:CV},
  {isActive:false, title:'SPA', subtitle:'Single page app', targetType:SPA},
  {isActive:false, title:'Position', subtitle:'Soyez facile à trouver', targetType:LOCATION},
  {isActive:false, title:'vFlyer', subtitle:'Flyer virtuel, fini le papier!', targetType:VFLYER},
]
//bg-[#E6E8EE]
export default function ChooseQRType() {
  // const [file, setFile] = useState(null)
  // const [open, setOpen] = useState(true)
  // const onClose = () => {
  //   setOpen(false)
  // }
  return (
      <div className="flex flex-wrap gap-2 md:gap-6 pt-6">
        {/* <WebSiteModal open={open} onClose={onClose} file={file} setFile={setFile}/> */}
        {qrTypes.map((target, index) => {
          return <QRTypeCard 
                  key={index}
                  targetType={target.targetType}
                  isActive={target.isActive}
                  title={target.title}
                  subtitle={target.subtitle}/>
        })}
      </div>
  );
}
