import { configureStore } from "@reduxjs/toolkit";
import stylingReducer from "../features/styling/stylingSlice";
import authReducer from "../features/user/authSlice";
import creationFlowReducer from "../features/creationFlowSlice";
import utilsReducer from "../features/utilsSlice";
import editCodeReducer from '../features/editCodeSlice'
import codesReducer from "../features/codesSlice";
import clientsReducer from "../features/clientsSlice";
import usersReducer from "../features/usersSlice";

export default configureStore({
  reducer: {
    styling: stylingReducer,
    auth: authReducer,
    codes: codesReducer,
    creationFlow: creationFlowReducer,
    utils: utilsReducer,
    editCode: editCodeReducer,
    clients: clientsReducer,
    users: usersReducer
    // signup: signupReducer,
    // signin: signinReducer,
  },
});
