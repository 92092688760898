import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../components/DeleteModal";
import EditQRPanel from "../components/EditQRPanel";
import QRCodesTable from "../components/QRCodesTable";
import { setDeleteChoice, setId, setDeleteModalOn } from "../features/utilsSlice";
import { fetchCodes, disableCode, deleteCode } from "../features/codesSlice";
import { setToolbarTitle } from "../features/utilsSlice";
import { IDLE, SUCCEEDED } from "../status";

export default function MyQR() {
  const 
    {fetchStatus, fetchError, data:qrCodes,
      deleteStatus, deleleteError,
      disableStatus, disableError
    } = useSelector(state => state.codes)
  const {deleteChoice, editPos, id, isDeleteModalOn, isEditPanelOn} = useSelector(state => state.utils)


 
  const dispatch = useDispatch()


  useEffect(() => {
    if(fetchStatus === IDLE) dispatch(fetchCodes())
    dispatch(setToolbarTitle("My QR Codes"))
  }, [])

  useEffect(() => {
    dispatch(setDeleteModalOn(false))
    switch (deleteChoice){
      case 'delete':
        dispatch(deleteCode(id))
        break
      case 'disable':
        dispatch(disableCode(id))
        break
      default:
    }
    dispatch(setId(null))
    dispatch(setDeleteChoice(null))
  }, [deleteChoice])

  // useEffect(() => {
  //   if(disableStatus === SUCCEEDED || deleteStatus === SUCCEEDED)
  //     dispatch(fetchCodes())
  // }, [disableStatus, deleteStatus])

  useEffect(() => {
      if(id !== null)
        dispatch(setDeleteModalOn(true))
  }, [id])

  return (

    <div className="">
      
      <section className="relative">
        <div className="container pt-4">
          {isDeleteModalOn && <DeleteModal/>}
          {isEditPanelOn && editPos !== null && qrCodes && <EditQRPanel qrCode={qrCodes[editPos]}/>}
          
          <QRCodesTable status={fetchStatus} qrCodes={qrCodes}/>
        </div>
      </section>
     
    </div>
    
  );
}
