import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import QRCodeStyling from "qr-code-styling";

import "./css/designQRCode.css";
import {SUCCEEDED, FAILED, LOADING, IDLE} from '../status'

import UrlInput from "../components/UrlInput";
import TargetURLInput from '../components/TargetURLInput'

import frames, {fr} from "../features/styling/frames";
import FrameSelector from "../features/styling/FrameSelector";

import { download, updateCode } from '../features/codesSlice'
import { useLocation } from "react-router-dom";
import { initStyling, setInnerColor, setOuterColor, setEdgeShape } from "../features/styling/stylingSlice";
import { initState, setDisplayName, setTarget } from "../features/editCodeSlice";


import { setToolbarTitle } from "../features/utilsSlice";
import StyleSelector from "../features/styling/StyleSelector";
import SimpleEdgeSelector from "../features/styling/SimpleEdgeSelector";


const cornersOptions = { S: "square", D: "dot", R: "extra-rounded" };
const cornersOptionsRev = { "square":"S", "dot":"D", "extra-rounded":"R" };

const size = 220;
const qrCodeStyling = new QRCodeStyling({
  width: size,
  height: size,
  dotsOptions: {
    color: "#4267b2",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "use-credentials",
    margin: 20,
  },
});
const baseURL = "https://unkode.me/qr/"

export default function EditQR({props}) {
    const {state: mQRCode} = useLocation()
    const stylingOptions = JSON.parse(mQRCode.options)

  const {
    codeShape, 
    color, 
    bgColor, 
    logo,
    frame,
    edgeShape,
    innerColor,
    outerColor,
  } = useSelector((state) => state.styling);

  const {updateStatus, updateError} = useSelector(state => state.codes)
  
  const {displayName, target} = useSelector(state => state.editCode)

  const dispatch = useDispatch()

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    dispatch(setToolbarTitle("Edit QR Code"))
    dispatch(
      initState({
        displayName: mQRCode.displayName, 
        status:mQRCode.status, 
        target:mQRCode.target
    }))

  }, [])

  const ref = useRef(null);
  useEffect(() => {
    qrCodeStyling.append(ref.current);
    qrCodeStyling.update({
        data:mQRCode.data,
    })
    dispatch(initStyling({
        codeShape: stylingOptions.dotsOptions.type,
        color: stylingOptions.dotsOptions.color,
        bgColor: stylingOptions.backgroundOptions.color,
        frame: mQRCode.frame,
        edgeShape: `${cornersOptionsRev[stylingOptions.cornersSquareOptions.type]}${cornersOptionsRev[stylingOptions.cornersDotOptions.type]}`,
        innerColor: stylingOptions.cornersDotOptions.color,
        outerColor: stylingOptions.cornersSquareOptions.color
    }))
  }, [dispatch]);

  useEffect(()=>{

  }, [updateStatus])

  useEffect(() => {
    qrCodeStyling.update({
      dotsOptions: {
        color: color,
      },
    });
  }, [color]);

  useEffect(() => {
    qrCodeStyling.update({
      backgroundOptions: {
        color: bgColor,
      },
    });
  }, [bgColor]);

  useEffect(() => {
    qrCodeStyling.update({
      dotsOptions: {
        type: codeShape,
      },
    });
  }, [codeShape]);

  // edges
  useEffect(() => {
    qrCodeStyling.update({
      cornersSquareOptions: {
        color: outerColor,
      },
    });
  }, [outerColor]);

  useEffect(() => {
    qrCodeStyling.update({
      cornersDotOptions: {
        color: innerColor,
      },
    });
  }, [innerColor]);

  useEffect(() => {
    const cornersSquare = cornersOptions[edgeShape[0]];
    const cornersDot = cornersOptions[edgeShape[1]];

    qrCodeStyling.update({
      cornersSquareOptions: {
        type: cornersSquare,
      },
      cornersDotOptions: {
        type: cornersDot,
      },
    });
  }, [edgeShape]);

  const handleDownload = () => {
    const options = qrCodeStyling._options//JSON.stringify(qrCodeStyling._options)
    dispatch(download({options, frame ,filename:"UnkodeMe.png"}))
  }

  const handleSave = () => {
    let data = {
      id:mQRCode.id, 
      target: target,
      displayName: displayName,
      frame: frame,
      options:JSON.stringify(qrCodeStyling._options)
    }
    dispatch(updateCode(data))
  }

  const scrollTop = (e) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  const scrollBottom = (e) => {
    window.scrollTo({top: document.body.offsetHeight, behavior: 'smooth'});
  }

  const handleDisplayName = (e) => {
    let value = e.target.value
    dispatch(setDisplayName(value))
  }

  const handleTarget = (target) => {
    dispatch(setTarget(target))
  }
  // <LogoSelector />;
  return (
    <section>
      <div className="container pt-6">
        <div className="flex flex-col-reverse md:flex-col lg:flex-row lg:mt-0">
          
          {/* Settings */}
          <div className="lg:max-w-[576px] mt-0 z-10">
              <div className=" w-full relative">
                <div className='py-1 title-3'>Display Name</div>
                <input className='input font-semibold' 
                    type="text" 
                    value={displayName||''}
                    onChange={handleDisplayName} />
                {/* <PenIcon className="w-5 text-gray-400 fixed"/> */}
              </div>  
              <div className="block">
                  <div className='title-3 mt-6'
                      >QR Code Data</div>
                  <div
                      className="py-2 text-xm text-default dark:text-default-dark pl-3 pr-4 w-full md:w-96"
                      >{mQRCode.data}
                  </div>
              </div>
              {(mQRCode.type === "default" || mQRCode.type === "WEBSITE") && target && 
                  <TargetURLInput title={'Target URL'} data={target} setData={handleTarget}/>
              }
              <FrameSelector />
              <StyleSelector />
              <SimpleEdgeSelector 
                      label="Edges"
                      shape={edgeShape}
                      innerColor={innerColor}
                      outerColor={outerColor}
                      setShape={setEdgeShape}
                      setInnerColor={setInnerColor}
                      setOuterColor={setOuterColor}/>
              
          </div>
          <div className="md:hidden mt-4 bg-light dark:bg-dark sticky top-12 z-20 my-2 -ml-2 pl-2 border-l-4 border-brand"
                onClick={scrollBottom}>
                <h2 className="title-4">Settings</h2>
              </div>
          {/* Preview */}
          <div className="w-full my-auto md:-mt-10 z-0">
            <div className='w-full relative min-h-[500px]'>
              {/* Frame */}
              <div className='absolute w-80 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-0'>
                <img className="w-full" alt='' src={frames[frame]} />
              </div>
               {/* QR Code */}
              <div
                className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-10
                p-3 rounded-lg'
                style={{ background: bgColor }}
                ref={ref}/>
              </div>
              {/* Save button */}
              <div className="flex justify-center -mt-8">
                <button
                    className="rounded-l-full z-10 py-2 pl-5 pr-2 text-light dark:text-dark text-l bg-success"
                    onClick={handleSave}
                  >Save
                </button>
                <button
                    className="rounded-r-full z-10 py-2 pl-2 pr-5 text-light dark:text-dark text-l bg-brand hover:bg-interactive dark:bg-brand-dark dark:hover:bg-interactive-dark"
                    onClick={handleDownload}
                  >Download
                </button>
              </div>
          </div>
          <div className="md:hidden bg-light dark:bg-dark sticky top-4 z-20 my-2 -ml-2 pl-2 border-l-4 border-brand"
              onClick={scrollTop}>
              <h2 className="title-4">Preview</h2>
          </div>
        </div>
      </div>
    </section>
   
  );
}