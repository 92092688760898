import React, { useState, useEffect } from "react";
import { Popover } from "@headlessui/react";

import ColorPicker from "../../components/ColorPicker";
import EdgeRadioGroup from "./EdgeRadioGroup";

import edges, { edgeSS, edgeRS, edgeDS, edgeDD, edgeSD } from "./edges";

export default function SimpleEdgeSelector({
  label,
  shape,
  innerColor,
  outerColor,
  setShape,
  setInnerColor,
  setOuterColor,
}) {
  // depending on label update the approriate edge (top left, top right ...)
  const [edgeShape, setEdgeShape] = useState(edges[shape]);

  useEffect(() => {
    setEdgeShape(edges[shape]);
  }, [shape]);
  return (
    <div>
      <div className="mb-2 title-3">
        {label}
      </div>
      <div className="flex">
        <Popover className="relative">
          <Popover.Button>
            <div className="p-2 hover:bg-subtler dark:hover:bg-subtler-dark rounded w-12 h-12 border mr-4">
              <img src={edgeShape} />
            </div>
          </Popover.Button>

          <Popover.Panel className="absolute z-10">
            <EdgeRadioGroup shape={shape} setShape={setShape} />
          </Popover.Panel>
        </Popover>
        <ColorPicker
          color={innerColor}
          label="Inner color"
          setColor={setInnerColor}
        />
        <ColorPicker
          adapt={true}
          color={outerColor}
          label="Outer color"
          setColor={setOuterColor}
        />
      </div>
    </div>
  );
}
