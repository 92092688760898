import React, { useState } from 'react'
import QRCodeRow from './QRCodeRow'

import {SUCCEEDED, FAILED, LOADING, IDLE} from '../status'
import OptionsSheet from './OptionsSheet'

export default function QRCodesTable({status, qrCodes}) {
    const [qrCode, setQrCode] = useState(null)
    const [optionsVisible, setOptionsVisible] = useState(false)

    return (
        <div className="shadow rounded-lg my-4 overflow-x-auto">
            <div className="min-w-full">
                {optionsVisible && 
                    <OptionsSheet qrCode={qrCode} 
                        setOptionsVisible={setOptionsVisible}/>
                }
                <table className='min-w-full leading-normal '>
                    <thead className='thead md:table-header-group hidden'>
                    <tr>
                        <th className='thead-th'>Name</th>
                        <th className='thead-th'>Date</th>
                        {/* <th className='thead-th'>Tag</th> */}
                        <th className='thead-th'>Link</th>
                        <th className='thead-th'>Scans</th>
                        <th className='thead-th'>Status</th>
                        <th className='thead-th'></th>
                    </tr>
                    </thead>
                    <tbody className='divide-y divide-subtil'>
                        {status === SUCCEEDED && qrCodes &&
                            qrCodes.map((qrCode, index) => {
                                return <QRCodeRow qrCode={qrCode} key={index} index={index} 
                                    setOptionsVisible={setOptionsVisible}
                                    setQrCode={setQrCode}/>
                            })
                        }
                    </tbody>
                
                </table>
            </div>
        </div>
    )
}
