import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TwitterPicker } from "react-color";
import { Popover } from "@headlessui/react";

const colors = [
  "#000000",
  "#ffffff",
  "#FCB900",
  "#FF6900",
  "#EB144C",
  "#7BDCB5",
  "#00D084",
  "#0693E3",
  "#F78DA7",
  "#9900EF",
];

export default function ColorPicker({ adapt, label, color, setColor }) {
  const dispatch = useDispatch();
  const [pickerVisibility, setPickerVisibility] = useState("hidden");
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    // console.log(color);
  }, [color]);

  let handleChangeComplete = (color) => {
    dispatch(setColor(color.hex));
  };

  let handleInputChange = (event) => {
    let color = event.target.value;
    // console.log(color);
    dispatch(setColor(color));
    // dispatch(setEdgeInnerColor());
  };

  let handleClick = (e) => {
    let w = window.innerWidth;
    let x = e.pageX + 200 < w ? e.pageX - 10 : e.pageX - 200;
    let y = e.pageY + 20;
    setTop(y);
    setLeft(x);
    setPickerVisibility(pickerVisibility == "hidden" ? "block" : "hidden");
  };

  //   let handleBlur = (e) => {
  //     if (e.currentTarget === e.target) {
  //       console.log("unfocused self");
  //     } else {
  //       console.log("unfocused child", e.target);
  //     }
  //     if (!e.currentTarget.contains(e.relatedTarget)) {
  //       // Not triggered when swapping focus between children
  //       console.log("focus left self");
  //     }
  //   };
  return (
    <div>
       <Popover className="relative">
        <Popover.Button>
      <div className="color-input mr-4">
        <span className="ml-2.5 px-1.5 text-subtle dark:text-subtle bg-light dark:bg-dark absolute -top-2 text-xs">
          {label}
        </span>
        <input
          className="border rounded w-full h-12 px-4 p-2"
          placeholder="#000000"
          value={color}
          onChange={handleInputChange}
        />
        <div
          className="color-indicator h-6 w-6 rounded border"
          style={{ background: color }}
          onClick={(e) => {
            handleClick(e);
          }}
        ></div>
      </div>
      {/* <div
        className={`mt-4 absolute z-10 ${pickerVisibility}`}
        style={{ top: top, left: left }}
      >
      </div> */}
      </Popover.Button>

        <Popover.Panel className={`absolute z-10 sm:left-0 ${adapt?'-left-36':''}`}>
        <TwitterPicker
          colors={colors}
          onChangeComplete={handleChangeComplete}
        />
        </Popover.Panel>
      </Popover>
    </div>
  );
}
