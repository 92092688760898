
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setMenuOn } from '../features/utilsSlice'
import { Link } from 'react-router-dom';
import { logout } from '../features/user/authSlice';

export default function MenuMobile() {
  const isMenuOn = useSelector(state => state.utils.isMenuOn)

  const dispatch = useDispatch()
  
  const hideMenu = (e) => {
    dispatch(setMenuOn(false))  
  }
  
  const handleLogout = () => {
    hideMenu()
    dispatch(logout())
  }
  return (
    <div className={`fixed top-0 w-full h-full visible md:hidden z-40 ${isMenuOn?'right-0':'right-[-100%]'}`}
    style={{transition: "right 150ms ease-in-out"}}>
        
          <div className="bg-light dark:bg-dark absolute w-full h-full z-10 ">
            <div className="absolute right-0 w-fit mt-5 mr-5 z-20">
              <CloseIcon className="ic-l text-default dark:text-default-dark"
                onClick={hideMenu}/>
            </div>
            <div className="absolute grid gap-y-4 content-center 
                justify-center w-full h-full pb-10
                mobile-menu-link">
              <Link className="w-fit mx-auto" to="/myqrs"
                onClick={hideMenu}>
                <span>Mes QR Codes</span>
              </Link>
              <Link className="w-fit mx-auto" to="/analysis"
                onClick={hideMenu}>
                <span>Analyses</span>
              </Link>
              <Link className="w-fit mx-auto" to="/newqr"
                onClick={hideMenu}>
                <span>Créer QR Code</span>
              </Link>
              <Link className="w-fit mx-auto" to="/fidelity"
                onClick={hideMenu}>
                <span>Fidélité</span>
              </Link>
              <Link className="w-fit mx-auto" to="/settings"
                onClick={hideMenu}>
                <span>Paramètres</span>
              </Link>

              <span className="absolute left-1/2 -translate-x-1/2 
                bottom-32 
                w-fit text-error dark:text-error-dark text-l font-semibold"
                onClick={handleLogout}
              >Se déconnecter</span>

            </div>
            
          </div>
    </div>
  );
}

const CloseIcon = (props) => {
  return (
    <svg 
      {...props}
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 20 20" 
      fill="currentColor">
      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
  )
}