import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, RadioGroup } from "@headlessui/react";

import edges, { edgeSS, edgeRS, edgeDS, edgeDD, edgeSD } from "./edges";

export default function EdgeRadioGroup({ shape, setShape }) {
  const dispatch = useDispatch();

  const handleChange = (val) => {
    dispatch(setShape(val));
  };

  return (
    <div className="shadow rounded p-3 bg-light dark:bg-dark">
      <RadioGroup value={shape} onChange={handleChange}>
        <div className="flex">
          <RadioGroup.Option className="inline-block m-1" value="SS">
            {({ checked }) => (
              <button
                className={
                  "p-1.5 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark rounded w-11 h-11 " +
                  (checked ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive" : "")
                }
              >
                <img src={edgeSS} />
              </button>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option className="inline-block m-1" value="RS">
            {({ checked }) => (
              <button
                className={
                  "p-1.5 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark rounded w-11 h-11 " +
                  (checked ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive" : "")
                }
              >
                <img src={edgeRS} />
              </button>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option className="inline-block m-1" value="DS">
            {({ checked }) => (
              <button
                className={
                  "p-1.5 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark rounded w-11 h-11 " +
                  (checked ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive" : "")
                }
              >
                <img src={edgeDS} />
              </button>
            )}
          </RadioGroup.Option>
        </div>
        <div className="flex">
          <RadioGroup.Option className="inline-block m-1" value="SD">
            {({ checked }) => (
              <button
                className={
                  "p-1.5 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark rounded w-11 h-11 " +
                  (checked ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive" : "")
                }
              >
                <img src={edgeSD} />
              </button>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option className="inline-block m-1" value="DS">
            {({ checked }) => (
              <button
                className={
                  "p-1.5 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark rounded w-11 h-11 " +
                  (checked ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive" : "")
                }
              >
                <img src={edgeDS} />
              </button>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option className="inline-block m-1" value="DD">
            {({ checked }) => (
              <button
                className={
                  "p-1.5 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark rounded w-11 h-11 " +
                  (checked ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive" : "")
                }
              >
                <img src={edgeDD} />
              </button>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    </div>
  );
}
