import React from 'react'

export default function TableRow({open, client, index}) {
  return (
    <tr key={index} className='bg-light dark:bg-dark hover:bg-hover dark:hover:bg-hover-dark flex flex-wrap flex-no wrap sm:table-row p-5 md:p-0'
      onClick={open}>
      <td className="td w-1/2 md:w-fit mb-2 md:mb-0">
          <div className="text-body font-semibold md:font-normal">{client.lastname}</div>
      </td>
      <td className="td mr-1 md:mr-0 -order-1 font-semibold md:font-normal">
          <div className="text-body">{client.firstname}</div>
      </td>
      <td className="td w-1/2 md:w-fit md:w-auto">
          <div className="text-body">n° {client.clientNum}</div>
      </td>
      <td className="td w-1/2 md:w-fit md:w-auto text-right md:text-left">
          <div className="text-body inline md:text-left font-semibold md:font-normal text-success dark:text-success-dark md:text-default md:dark:text-default-dark">{client.credit}</div>
          <div className='inline md:hidden'> pts.</div>
      </td>
    </tr>
  )
}
