import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { fetchClients } from '../../features/clientsSlice';
import { SUCCEEDED } from '../../status';
import { AddIcon, QRCodeIcon } from "../icons";
import TableRow from './TableRow';

export default function Dashboard() {
  const {clients, fetchStatus, fetchError} = useSelector(state => state.clients)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchClients())  
  }, [])

  const handleOpen = (client) => {
    navigate('/fidelity/client', {state: {client: client}})
    // console.log(client)
  }

  return (
    <div>
      {/* navigation buttons */}
      <div className="pt-4 grid grid-cols-2 gap-2">
        <Link className="fid-btn" to="/fidelity/scan">
          <QRCodeIcon className="fid-icon"/>
          <span>Scanner</span>
        </Link>

        <Link className="fid-btn" to="/fidelity/new">
          <AddIcon className="fid-icon"/>
          <span>Ajouter</span>
        </Link>
      </div>

      {/* Clients */}
      <div className="shadow rounded-lg my-4 overflow-x-auto">
          <div className="min-w-full">
            <table className='min-w-full leading-normal '>
              <thead className='thead md:table-header-group hidden'>
              <tr>
                <th className='thead-th'>Nom</th>
                <th className='thead-th'>Prénom</th>
                <th className='thead-th'>N° Client</th>
                <th className='thead-th'>Crédit</th>
              </tr>
              </thead>
              <tbody className='divide-y divide-subtil'>
                {fetchStatus === SUCCEEDED && clients &&
                    clients.map((client, index) => {
                      return <TableRow open={()=>handleOpen(client)} client={client} key={index} index={index}/>
                    })
                }
              </tbody>
          
            </table>
          </div>
        </div>
    </div>
  )
}
