import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { setToolbarTitle } from "../features/utilsSlice";


export default function Fidelity() {

  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(setToolbarTitle("Sassou"))
  }, [])

  return (
    <section>
      <div className="container pt-4">
        {/* Outlets */}
        <Outlet className="mt-4"/>
        
      </div>
    </section>

  )
}
