// import './App.css';
// import "bulma/css/bulma.min.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";

import Sidebar from "./components/Sidebar";
// import Home from "./pages/Home";
import Analysis from "./pages/Analysis";
import CreateQR from "./pages/CreateQR";
import MyQR from "./pages/MyQR";
import Signin from "./pages/Signin";
import PrivateRoute from "./components/PrivateRoute";
import { useEffect } from "react";
import { checkToken } from "./features/user/authSlice";
import ComingSoon from "./pages/ComingSoon";
import EditQR from "./pages/EditQR";
import MenuMobile from "./components/MenuMobile";
import Toolbar from "./components/Toolbar";
import Fidelity from "./pages/Fidelity";
import QRCodeScanner from "./components/fid/QRCodeScanner";
import AddClient from "./components/fid/AddClient";
import Dashboard from "./components/fid/Dashboard";
import ViewClient from "./components/fid/ViewClient";
import UpdateClient from "./components/fid/UpdateClient";
import UsersDashboard from "./components/users/UsersDashboard"
import AddUser from "./components/users/AddUser"
import UpdateUser from "./components/users/UpdateUser"
import Users from "./pages/Users";

const user = JSON.parse(localStorage.getItem('user'))

function App() {
  // const {user:currentUser} = useSelector(state => state.signin)
  const {user: currentUser} = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch(checkToken())
  }, [])

  return (
    <Router>
      <div className="font-Poppins">
        {currentUser && <Sidebar />}
        {/* {currentUser && <MenuBtnMobile />} */}
        {currentUser && <MenuMobile />}
        {currentUser && <Toolbar/>}
        
        <main className={`${currentUser?'md:ml-[4rem]':''} mt-12`}>
          <Routes>
            <Route exact path="/signin" element={<Signin />} />
            <Route element={<PrivateRoute currentUser={currentUser}/>}>
              <Route exact path="/" element={<MyQR />} />
              <Route exact path="/code/edit" element={<EditQR />} />
              <Route exact path="/myqrs" element={<MyQR />} />
              <Route exact path="/newqr" element={<CreateQR />} />
              <Route path="fidelity" element={<Fidelity />}>
                <Route path="" element={<Dashboard/>}/>
                <Route path="new" element={<AddClient/>}/>
                <Route path="scan" element={<QRCodeScanner/>}/>
                <Route path="client" element={<ViewClient/>}/>
                <Route path="update" element={<UpdateClient/>}/>
              </Route>
              <Route path="users" element={<Users />}>
                <Route path="" element={<UsersDashboard/>}/>
                <Route path="new" element={<AddUser/>}/>
                <Route path="update" element={<UpdateUser/>}/>
              </Route>
              <Route exact path="/analysis" element={<ComingSoon />} />
              <Route exact path="/settings" element={<ComingSoon />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
