import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMenuOn } from '../features/utilsSlice';
import { BackIcon, MenuIcon } from './icons';

export default function Toolbar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const title = useSelector(state => state.utils.toolbarTitle)
    const [navTop, setNavTop] = useState('top-0')
    const [lastScrollY, setLastScrollY] = useState(0)
    const [scrollY, setScrollY] = useState(0)

    useEffect(()=> {
        window.addEventListener('scroll', controllToolbar)
        return () => {
            window.removeEventListener('scroll', controllToolbar)
        }
    }, [])

    const controllToolbar = () => {
        setScrollY(window.scrollY)
    }
    
    const showMenu = () => {
        dispatch(setMenuOn(true))
    }

    useEffect(()=>{
        if(lastScrollY<scrollY)
            setNavTop("-top-16")
        else
            setNavTop("top-0")

        setLastScrollY(scrollY)
    }, [scrollY])

    const handleOnBackPressed = () => {
        navigate(-1)
    }

    return (
    <div className='md:ml-[4rem]'>
        <div className="md:container ">
            <div className={`bg-light dark:bg-dark px-4 md:px-0 fixed top-0 w-full flex py-4 ${navTop} md:top-0 z-30`}
                style={{transition: "top 250ms ease-in"}}>
            <BackIcon className="ic-l ic-color my-auto cursor-pointer"
                onClick={handleOnBackPressed}/>
            <h6 className='text-default dark:text-default-dark my-auto ml-2'>{title}</h6>
            <MenuIcon className="md:hidden ic-l ic-color my-auto ml-auto cursor-pointer"
                onClick={showMenu}/>
        </div>
  </div>
    </div>   
    )
}