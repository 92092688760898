import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab, setDisplayName as updateDisplayName } from '../features/creationFlowSlice';

export default function UploadFile({file, setFile}) {
    const {displayName:dName} = useSelector(state => state.creationFlow)
    const [displayName, setDisplayName] = useState(dName)

    const [displayNameError, setDisplayNameError] = useState(false)
    const [fileError, setFileError] = useState(false)
    
    const dispatch = useDispatch()
    // const {uploadPercent, uploadStatus, uploadError} = useSelector(state => state.codes)

    const handleDisplayChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        setDisplayName(value)
        setDisplayNameError(value === "")
    }

    const handleNext = (e) => {
        e.preventDefault()
        if(displayName === "" || file === null){
            setDisplayNameError(displayName==="")
            setFileError(file === null)
        }
        else{
            dispatch(updateDisplayName(displayName))
            dispatch(setCurrentTab(3))
        }
    }

    const handleBack = (e) => {
        e.preventDefault()
        dispatch(setCurrentTab(1))
    }


    const onFileChange = (e) => {
        const mfile = e.target.files[0]
        if(mfile){
            setFile(mfile)
        }
        setFileError(mfile === null)
    }
  return (
      <div 
        className='max-w-[900px] mx-auto mt-6 py-3 md:rounded-lg md:shadow bg-light dark:bg-dark'>
          <div className='border-b'>
            <h2 className='title-2 font-semibold mx-4 mb-3'>Add Document</h2>
          </div>

          <div className='m-6'>
            <span className="input-label">
                Display name
            </span>
            <input
                id='displayName'
                name='displayName'
                className="input"
                placeholder='My Shop QR Code'
                value={displayName}
                onChange={handleDisplayChange}
                />
            {displayNameError &&
                <span className='text-s text-error dark:text-error-dark'>Please enter a name</span>
            }
          </div>

          <div className='mx-6 my-4'>
            <span className="block input-label">
                Attach Document
            </span>
            <div className='flex flex-col border-2 border-dashed rounded w-full mt-2 py-6 gap-y-3'>
                <input 
                    type="file" 
                    accept='.pdf'
                    id='file'
                    name='file'
                    onChange={onFileChange}
                    className='hidden absolute w-0 h-0 opacity-0'/>
                <label 
                    className='button--ghost text-m self-center cursor-pointer'
                    htmlFor="file">
                    {file?file.name:'Browse Files'}
                </label>
            </div>
            {fileError &&
                <span className='text-s text-error dark:text-error-dark'>Please choose a PDF file</span>
            }
            <span className="caption mt-2">
                Accepted File Types: PDF
            </span>

            <div className="mt-8">
                
            </div>
            
            <div className="mt-8">
                <div className={`flex flex-row-reverse mt-8`}>
                    <input 
                        type="button"
                        value="Next"
                        onClick={handleNext}
                        className='button button--submit bg-brand dark:bg-brand-dark'/>
                    <input 
                        type="button"
                        value="Back"
                        onClick={handleBack}
                        className='button button--cancel'/>
                </div>
            </div>
          </div>
      </div>
  );    
}