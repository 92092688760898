import { createSlice } from "@reduxjs/toolkit";

const utilitySlice = createSlice({
    name:"utils",
    initialState:{
        deleteChoice: null, // cancel, disable, delete
        editPos: null,
        id: null,
        isDeleteModalOn: false,
        isEditPanelOn: false,
        isMenuOn: false,
        toolbarTitle: "",
    },
    reducers:{
        setDeleteChoice: (state, action) => {
            state.deleteChoice = action.payload
        },
        setEditPos: (state, action) => {
            state.editPos = action.payload
        },
        setId: (state, action) => {
            state.id = action.payload
        },
        setDeleteModalOn: (state, action) => {
            state.isDeleteModalOn = action.payload
        },
        setEditPanelOn: (state, action) => {
            state.isEditPanelOn = action.payload
        },
        setMenuOn: (state, action) => {
            state.isMenuOn = action.payload
        },
        setToolbarTitle: (state, action)=>{
            state.toolbarTitle = action.payload
        }
    }
})

export const {
    setDeleteChoice, 
    setEditPos, 
    setId, 
    setDeleteModalOn, 
    setEditPanelOn,
    setMenuOn,
    setToolbarTitle
} = utilitySlice.actions

export default utilitySlice.reducer