import edgeSS from "../../images/edge-squares.svg";
import edgeSD from "../../images/edge-square-circle.svg";
import edgeRS from "../../images/edge-rounded-square.svg";
import edgeRD from "../../images/edge-rounded-circle.svg";
import edgeDD from "../../images/edge-circles.svg";
import edgeDS from "../../images/edge-circle-square.svg";

const edges = {
  SS: edgeSS,
  SD: edgeSD,
  RS: edgeRS,
  RD: edgeRD,
  DD: edgeDD,
  DS: edgeDS,
};

export default edges;
export { edgeSS, edgeSD, edgeRS, edgeRD, edgeDD, edgeDS };
