import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'

import {SUCCEEDED, FAILED, LOADING} from '../status'

import { signin } from '../features/user/authSlice'

// const PASSWORD_REGEX =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
const signupSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(8).required()
})

export default function Signin() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {user, signinStatus, signinError:error} = useSelector((state) => state.auth)


    useEffect(() => {
        if(signinStatus === SUCCEEDED && user!==null)
            navigate('/myqrs')
    }, [user, signinStatus])

    return (
        <div className='grid grid-cols-1 content-center w-full h-[100vh] bg-subtler dark:bg-subtler-dark -mt-12 -mb-20 pb-12'>
            <div className='flex justify-center tracking-widest mb-6'>
                <h4 className='title-brand'>UnkodeMe</h4>
            </div>
            <div className='flex flex-1 justify-center mx-4 md:mx-0'>
                <Formik
                    initialValues={{email:'', password:''}}
                    validationSchema={signupSchema}
                    onSubmit={(values) => {
                        dispatch(signin({email:values.email, password:values.password}))
                    }}
                >
                    {/* onSubmit={props.submitForm()} */}
                    {(props) => (
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            props.submitForm()
                            }}
                            className='w-full max-w-xl bg-light dark:bg-dark rounded-lg shadow-md p-6 mt-4'>
                            <div >
                            <div className="w-full md:w-full px-3 mb-6 mt-6">
                                <label className="input-label">Email</label>
                                {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                                <input className="input"
                                    type="email"
                                    name='email'
                                    placeholder='Email'
                                    autoComplete='email'
                                    value={props.values.email}
                                    onChange={props.handleChange('email')}
                                    onBlur={props.handleBlur('email')}/>
                                    {props.touched.email && props.errors.email && 
                                        <label className='text-error dark:text-error-dark text-s ml-2'>Please enter a correct email!</label>
                                    }
                            </div>

                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="input-label">Password</label>
                                {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                                <input className="input"
                                    type="password"
                                    placeholder='Password'
                                    value={props.values.password}
                                    onChange={props.handleChange('password')}
                                    onBlur={props.handleBlur('password')}/>
                                {props.touched.password && props.errors.password &&
                                    <label className='text-error dark:text-error-dark text-s ml-2'>Password must be 8 or more characters</label>
                                }
                            </div>

                            <div className="w-full md:w-full mb-6">
                                {signinStatus===FAILED && error !== 'Wrong token' &&
                                    <div className='flex ml-2 text-error dark:text-error-dark'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <label className='text-m ml-2'>{error}</label>
                                    </div>
                                }           
                            </div>

                            <div className="w-full px-3 mb-6">
                                <input className="button button--full button--submit bg-brand dark:bg-brand" type="submit" name="" value="Sign in"
                                    />                       
                            </div>
                        </div>
                        </form>
                    
                    )}
                </Formik>
            </div>
        </div>
        
    )
}
