import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../services/auth-header";

import {SUCCEEDED, FAILED, LOADING, IDLE} from '../status'

/**
 * fetch all Users
 */
 export const fetchUsers = createAsyncThunk(
  "users/fetch",
  async(params, {rejectWithValue}) => {
      return await axios.get('users/list', {
              params: params,
              headers: authHeader()
          })
          .then(res => {
              if(res.data.status !== "succeeded")
                  return rejectWithValue(res.data.msg)
              else
                  return res.data
          })
          .catch(err => rejectWithValue(err.message) )
  }
)

/////////////////////////////////
// Register new User
////////////////////////////////
export const registerUser = createAsyncThunk(
  "users/register",
  async({firstname, lastname, email, password1, password2}, {rejectWithValue}) => {
      return await axios.post('users/register', {
          email,
          password1,
          password2,
          firstname,
          lastname
      }, {headers: authHeader()})
      .then(res => {
          if(res.data.status !== SUCCEEDED)
              return rejectWithValue(res.data.msg)
          else
              return res.data
      })
      .catch(err => rejectWithValue(err.message))
  }
)

/////////////////////////////////
// Delete User
////////////////////////////////
export const deleteUser = createAsyncThunk(
  "users/delete",
  async(id, {rejectWithValue}) => {
      return await axios.delete('users/delete', {headers: authHeader(), data:{id:id}})
      .then( resp => {
          if(resp.data.status !== SUCCEEDED)
              return rejectWithValue(resp.data.msg)
          return resp.data
      })
      .catch(err => {
          rejectWithValue(err.message)
      })
  }
)

/**
 * Update QR Code
 */
 export const updateUser = createAsyncThunk(
  "users/update",
  async(data, {rejectWithValue}) => {
      return await axios.patch('users/update', data, {headers: authHeader()})
      .then( resp => {
          if(resp.data.status !== SUCCEEDED)
              return rejectWithValue(resp.data)
          return resp.data
      })
      .catch(err => rejectWithValue(err.message))
  }
)

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState:{
    users: [],
      fetchStatus: IDLE,
      fetchError: null,
      registerStatus: IDLE,
      registerError: null,
      deleteStatus: IDLE,
      deleteError: null,
      updateStatus: IDLE,
      updateError: null
  },
  reducers: {
    initDeleteStatus: (state, action) => {
      state.deleteStatus = IDLE
      state.deleteError = null
    },
    initUpdateStatus: (state, action) => {
      state.updateStatus = IDLE
      state.updateError = null
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.fetchStatus = LOADING
      state.fetchError = null
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.fetchStatus = SUCCEEDED
      state.users = action.payload.data
    },
    [fetchUsers.rejected]: (state, action) => {
      state.fetchStatus = FAILED
      state.fetchError = action.payload
    },
    /////// Register
    [registerUser.pending]: (state, action) => {
      state.resgiterStatus = LOADING
      state.resgiterError = null
    },
    [registerUser.fulfilled]: (state, action) => {
      state.resgiterStatus = SUCCEEDED
      var newUser = action.payload.user
      state.users = [...state.users, newUser]
      // state.users = state.users.map(user => user.id === newUser.id? newUser:user)
    },
    [registerUser.rejected]: (state, action) => {
      state.resgiterStatus = FAILED
      state.resgiterError = action.payload
    },
    /////// Delete
    [deleteUser.pending]: (state, action) => {
      state.deleteStatus = LOADING
      state.deleteError = null
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.deleteStatus = SUCCEEDED
      var userId = action.payload.id
      state.users = state.users.filter(user => user.id !== userId)
    },
    [deleteUser.rejected]: (state, action) => {
      state.deleteStatus = FAILED
      state.deleteError = action.payload
    },
    ////// Update
    // Update
    [updateUser.pending]: (state, action) => {
      state.updateStatus = LOADING
      state.updateError = null
    },
    [updateUser.fulfilled]: (state, action) => {
      state.updateStatus = SUCCEEDED
      const update = action.payload.user
      state.users = state.users.map(user => user.id == update.id?update:user)
      state.fetchError = null
    },
    [updateUser.rejected]: (state, action) => {
      state.updateStatus = FAILED
      state.updateError = action.payload
    },
  }
})

export const {initDeleteStatus, initUpdateStatus, fetchError, fetchStatus, registerStatus, registerError,deleteError, deleteStatus, updateStatus, updateError} = usersSlice.actions
export default usersSlice.reducer