import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RadioGroup } from "@headlessui/react";

import { setFrame } from "./stylingSlice";

import icFrame0 from "../../images/ic-frame-0.svg";
import icFrame1 from "../../images/ic-frame-1.svg";
import icFrame2 from "../../images/ic-frame-2.svg";
import icFrame3 from "../../images/ic-frame-3.svg";
import icFrame4 from "../../images/ic-frame-4.svg";
import icFrame5 from "../../images/ic-frame-5.svg";
import icFrame6 from "../../images/ic-frame-6.svg";
import icFrame7 from "../../images/ic-frame-7.svg";

// options: rounded, extra-rounded, square, dots, classy, classy-rounded
export default function FrameSelector() {
  const dispatch = useDispatch();

  const { frame } = useSelector((state) => state.styling);
  
  const handleSelection = (frame) => {
    dispatch(setFrame(frame));
  };

  return (
    <div className="mt-6">
      <div className="mb-4 title-3">
        Code Frame
      </div>
      <div>
        <RadioGroup value={frame} onChange={handleSelection}>
          <div className="flex overflow-x-auto">
            <RadioGroup.Option className="inline-block m-1" value={"0"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="0" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame0} />
                </button>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option className="inline-block m-1" value={"1"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="1" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame1} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"3"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="3" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame3} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"4"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="4" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame4} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"7"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="7" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame7} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"5"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="5" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame5} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"6"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="6" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame6} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"2"}>
              {({ checked }) => (
                <button
                  className={
                    "rounded w-20 h-20 hover:bg-hover-secondary dark:hover:bg-hover-secondary-dark" +
                    (checked || frame=="2" 
                      ? " bg-hover-secondary dark:bg-hover-secondary border border-item-interactive dark:border-item-interactive-dark p-0" 
                      : " bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img className="w-12 ml-4" src={icFrame2} />
                </button>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
