import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'
import authHeader from "../../services/auth-header";

// import AuthService from '../../services/auth.service'
import {API_URL} from '../config'
import {SUCCEEDED, FAILED, LOADING, IDLE} from '../../status'

axios.defaults.baseURL= API_URL

const user = JSON.parse(localStorage.getItem("user"));

/////////////////////////////////
// Sign in
////////////////////////////////
export const signin = createAsyncThunk(
    "auth/signin",
    async({email, password}, {rejectWithValue}) => {
        return await axios.post('login', {
            email,
            password
        })
        .then(resp => {
            if(resp.data.status !== SUCCEEDED)
                    return rejectWithValue(resp.data)
            if (resp.data.accessToken)
                localStorage.setItem("user", JSON.stringify(resp.data))
            else rejectWithValue('Did not receive accessToken')
            return resp.data;
        })
        .catch(err => rejectWithValue(err.message))
    }
)

/////////////////////////////////
// Sign up
////////////////////////////////
export const signup = createAsyncThunk(
    "auth/signup",
    async({firstname, lastname, email, password1, password2}, {rejectWithValue}) => {
        return await axios.post('register', {
            email,
            password1,
            password2,
            firstname,
            lastname
        })
        .then(res => {
            if(res.data.status !== SUCCEEDED)
                return rejectWithValue(res.data.msg)
            else
                return res.data
        })
        .catch(err => rejectWithValue(err.message))
    }
)

/////////////////////////////////
// logout
////////////////////////////////
export const logout = createAsyncThunk(
    "auth/logout",
    () => {
        localStorage.removeItem("user")
    }
)

/////////////////////////////////
//  check token validity
/////////////////////////////////

export const checkToken = createAsyncThunk(
    "auth/checkToken",
    async(_, {rejectWithValue}) => {
        return await axios.get('checktoken', {headers: authHeader()})
        .then(res => {
            if(res.data.status !== SUCCEEDED)
                return rejectWithValue(res.data.msg)
            else
                return res.data
        })
        .catch(err => rejectWithValue(err.message))
    }
)

/////////////////////////////////
// Reducers
////////////////////////////////
const initialState= {
    user:null,
    status: IDLE,
    statusSignup: IDLE,
    errorSignup: null,
    error: null,
    signinStatus: IDLE,
    signinError: null
}

const authSlice = createSlice({
    name: 'signin',
    initialState,

    extraReducers: {
        //////////////////////////////////////
        //  sign in
        //////////////////////////////////////
        [signin.pending]: (state, action) => {
            state.signinStatus = LOADING
            state.signinError = null
        },
        [signin.fulfilled]: (state, action) => {
            state.signinStatus = SUCCEEDED
            state.user = action.payload.userId
        },
        [signin.rejected]: (state, action) => {
            state.signinStatus = FAILED
            state.signinError = action.payload.msg
        },
        //////////////////////////////////////
        //  sign up
        //////////////////////////////////////
        [signup.pending]: (state, action) => {
            state.statusSignup = LOADING
            state.errorSignup = null
        },
        [signup.fulfilled]: (state, action) => {
            state.statusSignup = SUCCEEDED
            state.user = action.payload.userId
        },
        [signup.rejected]: (state, action) => {
            console.log(action.payload)
            state.statusSignup = FAILED
            state.errorSignup = action.payload
        },
         //////////////////////////////////////
        //  logout
        //////////////////////////////////////
        [logout.fulfilled]: (state, action) => {
            state.status = SUCCEEDED
            state.user = null
        },
        //////////////////////////////////////
        //  check token
        //////////////////////////////////////
        [checkToken.pending]: (state, action) => {
            state.status = LOADING
            state.error = null
        },
        [checkToken.fulfilled]: (state, action) => {
            state.status = SUCCEEDED
            state.user = action.payload.userId
        },
        [checkToken.rejected]: (state, action) => {
            state.status = FAILED
            state.error = action.payload
        }
    }
})

export const {statusSignup, errorSignup} = authSlice.actions
const { reducer } = authSlice;
export default reducer;