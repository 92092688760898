import React, {useEffect, useState} from 'react'
import QrScan from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { findClient, resetClient } from '../../features/clientsSlice';


export default function QRCodeScanner() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {client, findStatus, findError} = useSelector(state => state.clients)
  const [clientNum, setClientNum] = useState(null);

  useEffect(()=>{
    if(clientNum)
      dispatch(findClient(clientNum))
  }, [clientNum])

  useEffect(()=>{
    if(client != null && clientNum)
      navigate('/fidelity/client', {state: {client: client}})
  }, [client])

  const handleScan = (result) => {
    if(result && !clientNum){
      setClientNum(result.split("/").pop())
    }
  }

  const handleError = err => {
    console.error(err)
  }

  return (
    <div className='shadow rounded p-1 mt-[25%]'>
      <QrScan
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
    </div>
  )
}
