import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon, EditIcon } from '../icons'

export default function UserRow({user, index, handleDelete}) {
  const navigate = useNavigate()

  const handleEdit = (e) => {
    navigate('/users/update', {state: {user: user}})
  }

  return (
    <tr key={index} className='bg-light dark:bg-dark hover:bg-hover dark:hover:bg-hover-dark flex flex-wrap flex-no wrap sm:table-row p-5 md:p-0'>
      <td className="td mr-1 md:mr-0 -order-1 font-semibold md:font-normal">
          <div className="text-body">{user.id}</div>
      </td>
      <td className="td w-1/2 md:w-fit mb-2 md:mb-0">
          <div className="text-body font-semibold md:font-normal">{user.firstname}</div>
      </td>
      <td className="td mr-1 md:mr-0 -order-1 font-semibold md:font-normal">
          <div className="text-body">{user.lastname}</div>
      </td>
      <td className="td w-1/2 md:w-fit md:w-auto">
          <div className="text-body">{user.email}</div>
      </td>
      <td className="td w-1/2 md:w-fit md:w-auto">
          <div className="text-body">{user.plan}</div>
      </td>
      <td className="py-3 px-6 text-center hidden md:table-cell">
        <div className="flex item-center justify-center">
            <div className="edit"
                onClick={handleEdit}>
                <EditIcon/>
            </div>
            <div className="delete"
                onClick={() => handleDelete(user.id)}>
                <DeleteIcon/>
            </div>
        </div>
      </td>
    </tr>
  )
}
