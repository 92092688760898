import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../services/auth-header";

import {SUCCEEDED, FAILED, LOADING, IDLE} from '../status'

/**
 * Add new Client
 */
 export const addClient = createAsyncThunk(
  "client/add",
  async({firstname, lastname, credit}, {rejectWithValue}) => {
      return await axios.post('client/add', {firstname, lastname, credit}, {headers: authHeader()})
      .then( resp => {
          if(resp.data.status !== SUCCEEDED)
              return rejectWithValue(resp.data)
          return resp.data
      })
      .catch(err => rejectWithValue(err.message))
  }
)

/**
 * List Clients
 */
export const fetchClients = createAsyncThunk(
  "client/fetch",
  async(params, {rejectWithValue}) => {
    return await axios.get('client/fetch', {
      params,
      headers: authHeader()
    })
    .then(resp => {
      if(resp.data.status !== SUCCEEDED)
              return rejectWithValue(resp.data)
          return resp.data
    })
    .catch(err => rejectWithValue(err.message))
  }
)

/**
 * Find Clients
 */
 export const findClient = createAsyncThunk(
  "client/find",
  async(clientNum, {rejectWithValue}) => {
    return await axios.get(`client/find?id=${clientNum}`, {headers: authHeader()})
    .then(resp => {
      console.log(resp.data)
      console.log(clientNum)
      if(resp.data.status !== SUCCEEDED)
              return rejectWithValue(resp.data)
          return resp.data
    })
    .catch(err => rejectWithValue(err.message))
  }
)

/**
 * Update client
 */
export const updateClient = createAsyncThunk(
  "client/update",
  async(data, {rejectWithValue}) => {
    return await axios.patch('client/update', data, {headers: authHeader()})
    .then( resp => {
        if(resp.data.status !== SUCCEEDED)
            return rejectWithValue(resp.data)
        return resp.data
    })
    .catch(err => rejectWithValue(err.message))
}
)

export const downloadClient = createAsyncThunk(
  "client/download",
  async({options, clientNum}, {rejectWithValue}) => {
      return await axios.post('client/download', {options}, 
              {headers: authHeader(), responseType: 'arraybuffer'})
          .then(res => {
              try{
                  if(res.data.byteLength === 0)
                      return rejectWithValue('server error')

                  var blob = new Blob([res.data], {type: "image/png"});
                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = `client_${clientNum}.png`;
                  link.click();
                  return true
              }
              catch (err) {
                  return rejectWithValue(err.message)
              }
          })
          .catch(err => rejectWithValue(err.message))
  }
)

/**
 * Delete Client
 */
 export const deleteClient = createAsyncThunk(
  "client/delete",
  async(clientNum, {rejectWithValue}) => {
      return await axios.delete('client/delete', {headers: authHeader(), data:{clientNum:clientNum}})
      .then( resp => {
          if(resp.data.status !== SUCCEEDED)
              return rejectWithValue(resp.data)
          return resp.data
      })
      .catch(err => {
          rejectWithValue(err.message)
      })
  }
)

const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: null,
    clients:[],
    addStatus: IDLE,
    addError: null,
    fetchStatus: IDLE,
    fetchError: null,
    findStatus: IDLE,
    findError: null,
    downloadStatus:IDLE,
    downloadError: null,
    updateStatus: IDLE,
    updateError: null,
    deleteStatus: IDLE,
    deleteError: null
  },
  reducers: {
    resetAdd: (state, action)=>{
      state.addStatus = IDLE
    },
    resetFtech: (state, action)=>{
      state.fetchStatus = IDLE
    },
    resetUpdate: (state, action)=>{
      state.updateStatus = IDLE
    },
    resetClient: (state, action)=>{
      state.findStatus = IDLE
      state.client = null
    },
    resetDelete: (state, action)=>{
      state.deleteStatus = IDLE
    },
  },
  extraReducers: {
    // Add
    [addClient.pending]: (state, action) => {
      state.addStatus = LOADING
      state.addError = null
    },
    [addClient.fulfilled]: (state, action) => {
      state.addStatus = SUCCEEDED
      state.addError = null
    },
    [addClient.rejected]: (state, action) => {
      state.addStatus = FAILED
      state.addError = action.payload.msg
    },
    // Find one
    [findClient.pending]: (state, action) => {
      state.findStatus = LOADING
      state.findError = null
    },
    [findClient.fulfilled]: (state, action) => {
      state.findStatus = SUCCEEDED
      state.client = action.payload.data
      state.findError = null
    },
    [findClient.rejected]: (state, action) => {
      state.findStatus = FAILED
      state.findError = action.payload.msg
    },
    // Fetch
    [fetchClients.pending]: (state, action) => {
      state.fetchStatus = LOADING
      state.fetchError = null
    },
    [fetchClients.fulfilled]: (state, action) => {
      state.fetchStatus = SUCCEEDED
      state.clients = action.payload.data
      state.fetchError = null
    },
    [fetchClients.rejected]: (state, action) => {
      state.fetchStatus = FAILED
      state.fetchError = action.payload.msg
    },
    // Update
    [updateClient.pending]: (state, action) => {
      state.updateStatus = LOADING
      state.updateError = null
    },
    [updateClient.fulfilled]: (state, action) => {
      state.updateStatus = SUCCEEDED
      state.clients = state.clients.map(client => client)
      state.fetchError = null
    },
    [updateClient.rejected]: (state, action) => {
      state.updateStatus = FAILED
      state.updateError = action.payload.msg
    },
    // Download
    [downloadClient.pending]: (state, action)=> {
      state.downloadStatus = LOADING
    },
    [downloadClient.fulfilled]: (state, action)=> {
        state.downloadStatus = SUCCEEDED
        state.downloadError = null
    },
    [downloadClient.rejected]: (state, action)=> {
        state.downloadStatus = FAILED
        state.downloadError = action.payload.msg
    },
    // Delete
    [deleteClient.pending]: (state, action)=> {
      state.deleteStatus = LOADING
    },
    [deleteClient.fulfilled]: (state, action)=> {
        state.deleteStatus = SUCCEEDED
        state.deleteError = null
    },
    [deleteClient.rejected]: (state, action)=> {
        state.deleteStatus = FAILED
        state.deleteError = action.payload.msg
    },
  }
})

export const {resetAdd, resetFtech, resetUpdate, resetClient, resetDelete} = clientSlice.actions
export default clientSlice.reducer