import React, { useEffect }  from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'

import {SUCCEEDED, FAILED, LOADING} from '../../status';
import { signup } from '../../features/user/authSlice'
import { initUpdateStatus, updateUser } from '../../features/usersSlice';

const signupSchema = yup.object({
    email: yup.string().email().required(),
    firstname: yup.string().min(2).max(32).required(),
    lastname: yup.string().min(2).max(32).required()
})

export default function UpdateUser() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const {updateStatus, statusError} = useSelector((state) => state.users)
  var user = location.state.user

  // useEffect(() => {
  //   if(updateStatus === SUCCEEDED)
  //     dispatch(initUpdateStatus())
  // }, [updateStatus])

  const handleOk = () => {
    dispatch(initUpdateStatus())
    navigate(-1)
  }

  const handleSubmit = (values) => {
    dispatch(updateUser({id: user.id, firstname:values.firstname, lastname:values.lastname, email:values.email}))
  }

  return (
    <div className='grid grid-cols-1 content-center w-full h-[90vh]'>
      <div className='flex justify-center mx-4 md:mx-0'>
        {updateStatus === SUCCEEDED?
        <div className='flex flex-col rounded shadow p-6'>
          <span className='block'>Utilisateur mis à jour avec succès!</span>
          <div className='block mt-2 rounded px-2 w-fit bg-green-400 text-white
            hover:cursor-pointer hover:bg-green-500 self-center' onClick={handleOk}>OK</div>
        </div>
        :
        <Formik
          initialValues={{firstname:user.firstname, lastname:user.lastname, email:user.email}}
          validationSchema={signupSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <form 
                    onSubmit={(e) => {
                        e.preventDefault()
                        props.submitForm()}
                    }
                    className='w-full max-w-xl bg-light dark:bg-dark rounded-lg shadow-md p-6 mt-4'>
                    <div className="w-full md:w-full mb-6">
                        {updateStatus===FAILED && statusError !== 'Wrong token' &&
                            <div className='flex ml-2 text-error dark:text-error-dark'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <label className='text-md ml-2'>{statusError}</label>

                            </div>
                        }           
                    </div>
                    
                    <div className="flex w-full md:w-full px-3 mb-6 space-x-3">
                        <div className='w-1/2'>
                          <label className="input-label">Prénom</label>
                          {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                          <input className="input"
                              type="text"
                              name='firstname'
                              placeholder='Prénom'
                              value={props.values.firstname}
                              onChange={props.handleChange('firstname')}
                              onBlur={props.handleBlur('firstname')}/>
                              {props.touched.firstname && props.errors.firstname && 
                                  <label className='text-error dark:text-error-dark text-s ml-2'>
                                    Veuillez saisir un prénom!</label>
                              }
                        </div>
                        <div className='w-1/2'>
                          <label className="input-label">Nom</label>
                          {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                          <input className="input"
                              type="text"
                              name='lastname'
                              placeholder='Nom'
                              value={props.values.lastname}
                              onChange={props.handleChange('lastname')}
                              onBlur={props.handleBlur('lastname')}/>
                              {props.touched.lastname && props.errors.lastname && 
                                  <label className='text-error dark:text-error-dark text-s ml-2'>
                                    Veuillez saisir un nom!</label>
                              }
                        </div>
                    </div>

                    <div className="w-full md:w-full px-3 mb-6">
                        <label className="input-label">Email</label>
                        {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                        <input className="input"
                            type="email"
                            name='email'
                            placeholder='Email'
                            autoComplete='email'
                            value={props.values.email}
                            onChange={props.handleChange('email')}
                            onBlur={props.handleBlur('email')}/>
                            {props.touched.email && props.errors.email && 
                                <label className='text-error dark:text-error-dark text-s ml-2'>
                                  Veuillez entrer un mail valide!</label>
                            }
                    </div>

                    <div className="w-full md:w-full px-3 mb-6">
                        <input type='submit' className={`${updateStatus === SUCCEEDED?
                          'button button--full bg-success dark:bg-success-dark':
                          'button button--full bg-brand dark:bg-brand-dark'}`} value="Mettre à jour"/>                       
                    </div>
                </form>
            )}
        </Formik>

        }
      </div>
    </div>
  )
}
