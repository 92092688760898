import React, { useEffect } from 'react'
import { Formik, replace } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { addClient, resetAdd } from '../../features/clientsSlice'
import { IDLE, LOADING, SUCCEEDED } from '../../status'
import { Link, useNavigate } from 'react-router-dom'


const clientSchema = yup.object({
  firstname: yup.string().min(2).required(),
  lastname: yup.string().min(2).required(),
  credit: yup.number().min(0).required()
})

/* A form that allows you to add a client. */
export default function 
AddClient() {
  const dispatch = useDispatch()
  const {addStatus, addError} = useSelector(state => state.clients)
  let navigate = useNavigate();

  useEffect(()=>{
    if(addStatus === SUCCEEDED){
      setTimeout(()=>{
        dispatch(resetAdd())
        navigate(-1)
      }, 1000)
    }
  }, [addStatus])

  const refreshPage = () =>{
    window.location.reload();
  }

  return (
    <div>
      <Formik
          initialValues={{firstname:'', lastname:'', credit:0}}
          validationSchema={clientSchema}
          onSubmit={(values) => {
             dispatch(addClient(values))
          }}
      >
        {(props) => (
          <form onSubmit={(e) => {
            e.preventDefault()
            props.submitForm()
            }}>
              <div className="w-full md:w-full px-3 mb-6 mt-6">
                <label className="input-label">Prénom</label>
                {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                <input className="input"
                    type="string"
                    name='firstname'
                    placeholder='Prénom'
                    value={props.values.firstname}
                    onChange={props.handleChange('firstname')}
                    onBlur={props.handleBlur('firstname')}/>
                    {props.touched.firstname && props.errors.firstname && 
                        <label className='text-error dark:text-error-dark text-s ml-2'>Ce champ est obligatoire (min. 2 caractères)!</label>
                    }
              </div>

              <div className="w-full md:w-full px-3 mb-6 mt-6">
                <label className="input-label">Nom</label>
                {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                <input className="input"
                    type="string"
                    name='lastname'
                    placeholder='Nom'
                    value={props.values.lastname}
                    onChange={props.handleChange('lastname')}
                    onBlur={props.handleBlur('lastname')}/>
                    {props.touched.lastname && props.errors.lastname && 
                        <label className='text-error dark:text-error-dark text-s ml-2'>Ce champ est obligatoire (min. 2 caractères)!</label>
                    }
              </div>

              <div className="w-full md:w-full px-3 mb-6 mt-6">
                <label className="input-label">Crédit</label>
                {/* <!-- Using form state modifers, the classes can be identical for every input --> */}
                <input className="input"
                    type="number"
                    name='credit'
                    placeholder='Crédit'
                    value={props.values.credit}
                    onChange={props.handleChange('credit')}
                    onBlur={props.handleBlur('credit')}/>
                    {props.touched.credit && props.errors.credit && 
                        <label className='text-error dark:text-error-dark text-s ml-2'>Ce champ est obligatoire!</label>
                    }
              </div>

              <div className="w-full px-3 mb-6">
                  <input disabled={addStatus===LOADING} 
                    className={`${addStatus===LOADING?'animate-bounce':''} button button--full button--submit ${addStatus===SUCCEEDED?'bg-success dark:bg-success-dark':'bg-brand dark:bg-brand'}`} type="submit" name="" 
                    value={`${addStatus===LOADING?"Ajout en cours ...":addStatus===SUCCEEDED?"Client ajouté":"Ajouter"}`}
                      />                       
              </div>
              {/* <div className="w-full px-3 mb-6">
                  <button className="button button--full button--shadow text-subtle">Annuler</button>                       
              </div> */}
          </form>
        )}
      </Formik>
      
      {addError==="Wrong token" &&
        <div className='text-center'> 
          Vous n'êtes pas connecté. <span className="text-brand cursor-pointer" onClick={refreshPage}>Connectez-vous</span> d'abord
        </div>
      }
      
    </div>
  )
}
