import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import QRCodeStyling from "qr-code-styling";
import { useDispatch, useSelector } from 'react-redux';
import { setToolbarTitle } from '../../features/utilsSlice';
import { deleteClient, downloadClient, resetDelete } from '../../features/clientsSlice';

import DeleteConfirmation from '../DeleteConfirmation';
import { LOADING, SUCCEEDED } from '../../status';


const size = 260;
const qrCode = new QRCodeStyling({
  width: size,
  height: size,
  dotsOptions: {
    color: "#000000",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "use-credentials",
    margin: 20,
  },
});

/* Rendering a client's information. */
export default function ViewClient() {
  // let dispatch = useDispatch()
  const {downloadStatus, downloadError, deleteStatus, deleteError} = useSelector(state => state.clients)
  const [client, setClient] = useState(null)
  const [error, setError] = useState(false)
  const [url, setUrl] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  let navigate = useNavigate()
  let dispatch = useDispatch()
  let location = useLocation()
  
  let qrRef = useRef(null)

  useEffect(()=>{
    try{
      let clt = location.state.client
      let tmpUrl = `https://client.unkode.me/sassou/${clt.clientNum}`
      setClient(clt)
      setUrl(tmpUrl)
    }
    catch(err){
      setError(true)
    }
  }, [])
  
  useEffect(()=>{
    // dispatch(setToolbarTitle("Client"))
    if(url){
      qrCode.append(qrRef.current);
      qrCode.update({
        data: url
      })
    }
  }, [url])

  useEffect(()=>{
    if(deleteStatus === SUCCEEDED){
      setTimeout(()=>{
        dispatch(resetDelete())
        navigate(-1)
      }, 900)
    }
  }, [deleteStatus])

  const download = () => {
    dispatch(downloadClient({options: qrCode._options, clientNum: client.clientNum}))
  }
  const handleOpen = () => {
    navigate('/fidelity/update', {state: {client: client}})
  }

  const handleDelete = () => {
    setModalVisible(true)
  }

  const confirmDelete = () => {
    dispatch(deleteClient(client.clientNum))
  }

  return (
    <div className='h-[80vh]'>
      {client && 
        <ShowClient client={client} qrRef={qrRef} url={url} open={handleOpen} download={download} handleDelete={handleDelete}/>}
      {error &&
        <ErrorMessage navigate={navigate}/>
      }

      <DeleteConfirmation modalVisible={modalVisible}  
        setModalVisible={setModalVisible} 
        confirmDelete={confirmDelete}
        status={deleteStatus}
        successMessage='Client supprimé!'
        title={'Supprimer carte client?'}
        message={'Etes-vous sûr de vouloir supprimer cette carte client? Cette action est irréversible.'}/>
    </div>
  )
}

const ErrorMessage = ({navigate}) => {
  return (
    <div className='text-l h-full grid justify-center content-center'>
      <span>Circulez, il n'y a rien à voir ici!</span> 
      <span className='text-center text-interactive dark:text-interactive-dark' 
        onClick={()=>navigate(-1)}>Retourner au dashboard</span>
    </div>
  )
}
const ShowClient = ({client, qrRef, url, open, download, handleDelete}) => {
  return (
    <div className='grid justify-center'>
      <div className='grid mt-8 mb-6 justify-center'>
        <div className='w-fit h-fit rounded-lg shadow p-1' ref={qrRef}></div>
        <div className='text-2xs text-center mt-2'>{url}</div>
      </div>
      <div className='grid w-fit justify-self-center'>
        <span className='text-h6'>{client.lastname} {client.firstname}</span>
        <span className='text-h6'></span>
        <span className='text-h6'>n° {client.clientNum}</span>
        <span className='text-h6'>Crédit: {client.credit}€</span>
      </div>

      <button className='button button--full button--submit mt-8'
        onClick={open}>Modifier</button>

      <button className='button button--full button--download mt-2'
        onClick={download}>Télécharger</button>

      <button className='button button--full button--delete mt-2'
        onClick={handleDelete}>Supprimer</button>

    </div>
  )
}