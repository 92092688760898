import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab, setDisplayName as updateDisplayName, setTargetURL } from '../features/creationFlowSlice';
import { FAILED, SUCCEEDED } from '../status';

export default function Website() {
    const {displayName:dName, targetURL} = useSelector(state => state.creationFlow)
    const [displayName, setDisplayName] = useState(dName)
    const [target, setTarget] = useState(targetURL)

    const [displayNameError, setDisplayNameError] = useState(false)
    const [targetError, setTargetError] = useState()
    
    const dispatch = useDispatch()

    const onDisplayChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        setDisplayName(value)
        setDisplayNameError(value === "")
    }

    const onTargetChange = (e) => {
        let value = e.target.value
        setTarget(value)
        if(value === "")
            setTargetError(true)
        else if(targetError)
            setTargetError(false)
    };

    const handleNext = (e) => {
        e.preventDefault()
        if(target === "" || displayName === ""){
            setDisplayNameError(displayName==="")
            setTargetError(target === "")
        }
        else{
            dispatch(updateDisplayName(displayName))
            dispatch(setTargetURL(target))
            dispatch(setCurrentTab(3))
        }
    }

    const handleBack = (e) => {
        e.preventDefault()
        dispatch(setCurrentTab(1))
    }

  return (
      <div 
        className='max-w-[900px] mx-auto mt-6 py-3 md:rounded-lg md:shadow bg-light dark:bg-dark'>
          <div className='border-b'>
            <h2 className='title-2 font-semibold mx-4 mb-3'>Add Website Address</h2>
          </div>

          <div className='m-6'>
            <span className="input-label">
                Display name
            </span>
            <input
                id='displayName'
                name='displayName'
                className="input mt-2"
                placeholder='My Shop QR Code'
                value={displayName}
                onChange={onDisplayChange}
                />
            {displayNameError &&
                <span className='text-s text-error dark:text-error-dark'>Please enter a name</span>
            }
          </div>

          <div className='m-6'>
            <span className="input-label">
                Website Address
            </span>
            <input
                id='targetURL'
                name='targetURL'
                className="input mt-2"
                placeholder='www.example.com'
                value={target}
                onChange={onTargetChange}
                />
            {targetError &&
                <span className='text-s text-error dark:text-error-dark'>Target URL is mandatory</span>
            }
          </div>

          <div className='mx-6 my-4'>
            <div className="mt-10">
                <div className={`flex flex-row-reverse mt-8`}>
                    <input 
                        type="button"
                        value="Next"
                        onClick={handleNext}
                        className='button button--submit bg-brand dark:bg-brand-dark'/>
                    <input 
                        type="button"
                        value="Back"
                        onClick={handleBack}
                        className='button button--cancel'/>
                </div>
            </div>
          </div>
      </div>
  );    
}

const UploadIcon = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor">
            <path 
                strokeLinecap="round"
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
        </svg>
    )
}

const DocIcon = (props) => {
    return (
        <svg 
            {...props}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 20 20" 
            fill="currentColor">
            <path 
                fillRule="evenodd" 
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" 
                clipRule="evenodd" />
            <text x="5.5" y="12.3" fontSize="0.3em" fontWeight="bold" fill="white">PDF</text>
        </svg>
    )
}

const WarningIcon = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 20 20" 
            fill="currentColor">
            <path fillRule="evenodd" 
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" 
                clipRule="evenodd" />
        </svg>
    )
}

const RemoveDocIcon = (props) => {
    return (
        <svg 
            {...props}
            xmlns="http://www.w3.org/2000/svg"  
            viewBox="0 0 20 20" 
            fill="currentColor">
            <path
                fillRule="evenodd"
                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm1 8a1 1 0 100 2h6a1 1 0 100-2H7z" 
                clipRule="evenodd" />
        </svg>
    )
}
