import { createSlice } from "@reduxjs/toolkit";

const editCodeSlice = createSlice({
    name: 'qr/edit',
    initialState: {
        displayName: null,
        status: null,
        target: null
    },
    reducers:{
        initState: (state, action) => {
            // state.tags = action.payload.tags||[]
            state.displayName = action.payload.displayName
            state.status = action.payload.status
            state.target = action.payload.target
        },
        resetState: (state, action) => {
            // state.tags = action.payload.tags||[]
            state.displayName = null
            state.status = null
            state.target = null
        },
        setDisplayName: (state, action) => {
            state.displayName = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setTarget: (state, action) => {
            state.target = action.payload
        }
    }
})

export default editCodeSlice.reducer
export const {
        initState,
        resetState,
        setDisplayName,
        setStatus,
        setTarget
        } = editCodeSlice.actions