import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
// import { ChevronDownIcon } from '@heroicons/react/solid'

export default function StatusMenu({status, setStatus}) {
  
  return (
    <div className="w-56">
      <Menu as="div" className="inline-block">
        <div>
          <Menu.Button className={`inline-flex justify-center w-28 px-2 py-2 text-sm font-medium text-white rounded-md 
            ${status==='active'?'bg-green-500':(status==='disabled'?'bg-red-400':'bg-yellow-400')}`}>
            <span className='w-full capitalize'>{status}</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            {/* <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            /> */}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-40 w-28 mt-2 origin-top bg-light dark:bg-dark divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-green-600' : 'bg-green-500'
                    } text-white rounded-md  w-full px-2 py-2 text-sm`}
                    onClick={() => setStatus('active')}
                  >
                    Active
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-yellow-500' : 'bg-yellow-400'
                    } my-1 text-white rounded-md  w-full px-2 py-2 text-sm`}
                    onClick={() => setStatus('draft')}
                  >
                    Draft
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-red-500' : 'bg-red-400'
                    } text-white rounded-md  w-full px-2 py-2 text-sm`}
                    onClick={() => setStatus('disabled')}
                  >
                    Disabled
                  </button>
                )}
              </Menu.Item>
              </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

function EditActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  )
}