import React from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentTab, setTargetType } from '../features/creationFlowSlice'

import './css/card.css'

export default function QRTypeCard({targetType, isActive, title, subtitle}) {
    const dispatch = useDispatch()
    const handleClick= () => {
        if (isActive){
            dispatch(setCurrentTab(2))
            dispatch(setTargetType(targetType))
        }
    }
    return (
        <div className={`card md:card-md py-6 ${isActive?'card-active':'card-inactive'}`}
            onClick={handleClick}>
            <div className={`card-icon md:card-icon-md ${isActive?'icon-active':'icon-inactive'}`}></div>
            <div className='md:text-center px-4 md:px-6 md:mt-6'>
                <p className='title-2'>{title}</p>
                <p className='caption'>{subtitle}</p>
            </div>
        </div>
    )
}