import { createSlice } from "@reduxjs/toolkit";

export const stylingSlice = createSlice({
  name: "styling",
  initialState: {
    codeShape: "rounded",
    color: "#000000",
    bgColor: "#FFFFFF",
    logo: "none",
    frame: 0,
    edgeShape: "SS",
    innerColor: "#000000",
    outerColor: "#000000",
  },
  reducers: {
    initStyling: (state, action) => {
      state.codeShape = action.payload.codeShape
      state.color = action.payload.color
      state.bgColor = action.payload.bgColor
      state.frame = action.payload.frame
      state.edgeShape = action.payload.edgeShape
      state.innerColor = action.payload.innerColor
      state.outerColor = action.payload.outerColor
    },
    resetStyling: (state, action) => {
      state.codeShape= "rounded"
      state.color= "#000000"
      state.bgColor= "#FFFFFF"
      state.frame= 0
      state.edgeShape = "SS"
      state.innerColor = "#000000"
      state.outerColor = "#000000"
    },
    setCodeShape: (state, action) => {
      state.codeShape = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setBgColor: (state, action) => {
      state.bgColor = action.payload;
    },
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setFrame: (state, action) => {
      state.frame = action.payload;
    },
    setEdgeShape: (state, action) => {
      state.edgeShape = action.payload;
    },
    setInnerColor: (state, action) => {
      state.innerColor = action.payload;
    },
    setOuterColor: (state, action) => {
      state.outerColor = action.payload;
    },
  },
});

export const {
  initStyling,
  resetStyling,
  setCodeShape,
  setColor,
  setBgColor,
  setLogo,
  setFrame,
  setEdgeShape,
  setInnerColor,
  setOuterColor
} = stylingSlice.actions;

export default stylingSlice.reducer;
