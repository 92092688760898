import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../services/auth-header";

import {SUCCEEDED, FAILED, LOADING, IDLE} from '../status'

/**
 * fetch all my QR Codes
 */
export const fetchCodes = createAsyncThunk(
    "codes/fetch",
    async(params, {rejectWithValue}) => {
        return await axios.get('qr/list', {
                params: params,
                headers: authHeader()
            })
            .then(res => {
                if(res.data.status !== "succeeded")
                    return rejectWithValue(res.data.msg)
                else
                    return res.data
            })
            .catch(err => rejectWithValue(err.message) )
    }
)

/**
 * Add new QR Code
 */
 export const addCode = createAsyncThunk(
    "codes/add",
    async(data, {rejectWithValue}) => {
        return await axios.post('qr/add', data, {headers: authHeader()})
        .then( resp => {
            if(resp.data.status !== SUCCEEDED)
                return rejectWithValue(resp.data)
            return resp.data
        })
        .catch(err => rejectWithValue(err.message))
    }
)

/**
 * Get new short/ID value
 */
export const getShort = createAsyncThunk(
    "codes/short",
    async(_, {rejectWithValue}) => {
        return await axios.get('qr/short', {headers: authHeader()})
        .then( resp => {
            if(resp.data.status !== 'succeeded')
                return rejectWithValue(resp.data)
            return resp.data
        })
        .catch(er => rejectWithValue(er.message))
    }
)

/**
 * Update QR Code
 */
 export const updateCode = createAsyncThunk(
    "codes/update",
    async(data, {rejectWithValue}) => {
        return await axios.patch('qr/update', data, {headers: authHeader()})
        .then( resp => {
            if(resp.data.status !== SUCCEEDED)
                return rejectWithValue(resp.data)
            return resp.data
        })
        .catch(err => rejectWithValue(err.message))
    }
)

/**
 * Disable QRCode
 */
 export const disableCode = createAsyncThunk(
    "codes/disable",
    async(id, {rejectWithValue}) => {
        return await axios.patch('qr/disable', {id:id}, {headers: authHeader()})
        .then( resp => {
            if(resp.data.status !== SUCCEEDED)
                return rejectWithValue(resp.data)
            return resp.data
        })
        .catch(err => rejectWithValue(err.message))
    }
)

/**
 * Delete QR Code from DB
 */
export const deleteCode = createAsyncThunk(
    "codes/delete",
    async(id, {rejectWithValue}) => {
        return await axios.delete('qr/delete', {headers: authHeader(), data:{id:id}})
        .then( resp => {
            if(resp.data.status !== SUCCEEDED)
                return rejectWithValue(resp.data)
            return resp.data
        })
        .catch(err => {
            rejectWithValue(err.message)
        })
    }
)

/**
 * Download QRCode Image
 */
 export const download = createAsyncThunk(
    "codes/download",
    async({options, frame, filename}, {rejectWithValue}) => {
        return await axios.post('qr/download', {options, frame, filename}, 
                {headers: authHeader(), responseType: 'arraybuffer'})
            .then(res => {
                try{
                    if(res.data.byteLength === 0)
                        return rejectWithValue('server error')

                    var blob = new Blob([res.data], {type: "image/png"});
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    return true
                }
                catch (err) {
                    return rejectWithValue(err.message)
                }
            })
            .catch(err => rejectWithValue(err.message))
    }
)

/**
 * Upload PDF file to server
 */
 export const uploadFile = createAsyncThunk(
    "codes/upload",
    async({file, data}, {rejectWithValue, dispatch}) => {
        const formData = new FormData()
        formData.append('file', file)
        // 1. upload file
        return await axios.post('qr/upload', formData, {
            headers: {...authHeader(), 'Content-Type': 'multipart/form-data'},
            onUploadProgress: progressEvent => {
                const percent = parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total))
                dispatch(setUploadPercent(percent))
            }
        })
        .then( resp => {
            if(resp.data.status !== SUCCEEDED)
                return rejectWithValue(resp.data)
            // 2. add new QR Code to DB
            data["filesPath"] = resp.data.filePath
            dispatch(addCode(data))
        })
        .catch(err => rejectWithValue(err.message))
    }
)

const codesSlice = createSlice({
    name: 'codesSlice',
    initialState: {
        data: null,
        short: null,
        shortStatus: IDLE,
        shortError: null,
        fetchStatus:IDLE,
        fetchError: null,
        addStatus:IDLE,
        addError: null,
        disableStatus:IDLE,
        disableError: null,
        deleteStatus:IDLE,
        deleteError: null,
        downloadStatus:IDLE,
        downloadError: null,
        updateStatus:IDLE,
        updateError: null,
        uploadStatus:IDLE,
        uploadError: null,
        uploadPercent: 0,
        lastAddId: null
    },
    reducers:{
        init : (state, action) => {
            state.short = null
            state.lastAddId = null
            state.addStatus = IDLE
            state.updateStatus = IDLE
        },
        setUploadPercent: (state, action) => {
            state.uploadPercent = action.payload
        }
    },
    extraReducers: {
        [fetchCodes.pending]: (state, action)=> {
            state.fetchStatus = LOADING
        },
        [fetchCodes.fulfilled]: (state, action)=> {
            state.fetchStatus = SUCCEEDED
            state.data = action.payload.data
            state.fetchError = null
        },
        [fetchCodes.rejected]: (state, action)=> {
            state.fetchStatus = FAILED
            state.fetchError = action.payload.msg
        },
        [addCode.pending]: (state, action)=> {
            state.addStatus = LOADING
            state.lastAddId = null
        },
        [addCode.fulfilled]: (state, action)=> {
            state.addStatus = SUCCEEDED
            state.data = [...state.data, action.payload.qrCode]
            state.lastAddId = action.payload.qrCode.id
            state.addError = null
        },
        [addCode.rejected]: (state, action)=> {
            state.addStatus = FAILED
            state.addError = action.payload.msg
        },
        [getShort.pending]: (state, action)=> {
            state.shortStatus = LOADING
        },
        [getShort.fulfilled]: (state, action)=> {
            state.shortStatus = SUCCEEDED
            state.short = action.payload.short
            state.shortError = null
        },
        [getShort.rejected]: (state, action)=> {
            state.shortStatus = FAILED
            state.shortError = action.payload.msg
        },
        [disableCode.pending]: (state, action)=> {
            state.disableStatus = LOADING
        },
        [disableCode.fulfilled]: (state, action)=> {
            state.disableStatus = SUCCEEDED
            state.data = state.data.map(qrCode => qrCode.id===action.payload.qrCode.id?action.payload.qrCode:qrCode)
            state.disableError = null
        },
        [disableCode.rejected]: (state, action)=> {
            state.disableStatus = FAILED
            state.disableError = action.payload.msg
        },
        [deleteCode.pending]: (state, action)=> {
            state.delteStatus = LOADING
        },
        [deleteCode.fulfilled]: (state, action)=> {
            state.delteStatus = SUCCEEDED
            state.data = state.data.filter(qrCode => qrCode.id!==action.payload.id)
            state.deleteError = null
        },
        [deleteCode.rejected]: (state, action)=> {
            state.delteStatus = FAILED
            state.deleteError = action.payload.msg
        },
        [download.pending]: (state, action)=> {
            state.downloadStatus = LOADING
        },
        [download.fulfilled]: (state, action)=> {
            state.downloadStatus = SUCCEEDED
            state.downloadError = null
        },
        [download.rejected]: (state, action)=> {
            state.downloadStatus = FAILED
            state.downloadError = action.payload.msg
        },
        [updateCode.pending]: (state, action)=> {
            state.updateStatus = LOADING
        },
        [updateCode.fulfilled]: (state, action)=> {
            state.updateStatus = SUCCEEDED
            state.data = state.data.map(qrCode => qrCode.id===action.payload.qrCode.id?action.payload.qrCode:qrCode)
            state.updateError = null
        },
        [updateCode.rejected]: (state, action)=> {
            state.updateStatus = FAILED
            state.updateError = action.payload.msg
        },
        [uploadFile.pending]: (state, action)=> {
            state.uploadStatus = LOADING
        },
        [uploadFile.fulfilled]: (state, action)=> {
            state.uploadStatus = SUCCEEDED
            state.uploadError = null
        },
        [uploadFile.rejected]: (state, action)=> {
            state.uploadStatus = FAILED
            state.uploadError = action.payload.msg
            state.uploadPercent = 0
        },
    }
})

export const {init, setUploadPercent} = codesSlice.actions
export default codesSlice.reducer