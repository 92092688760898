import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import QRCodeStyling from "qr-code-styling";

import "./css/designQRCode.css";
import {SUCCEEDED, FAILED, LOADING, IDLE} from '../status'

import UrlInput from "../components/UrlInput";

import frames, {fr} from "../features/styling/frames";
import FrameSelector from "../features/styling/FrameSelector";
import StyleSelector from '../features/styling/StyleSelector'
import { setInnerColor, setOuterColor, setEdgeShape, resetStyling } from "../features/styling/stylingSlice";
import { download, addCode, getShort, updateCode, uploadFile } from '../features/codesSlice'
import SimpleEdgeSelector from "../features/styling/SimpleEdgeSelector";
import WarningModal from "../components/WarningModal";

const cornersOptions = { S: "square", D: "dot", R: "extra-rounded" };

const size = 220;
const qrCode = new QRCodeStyling({
  width: size,
  height: size,
  dotsOptions: {
    color: "#4267b2",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "use-credentials",
    margin: 20,
  },
});
const baseURL = "qr.unkode.me/"

export default function DesignQRCode({file}) {
  const {
    codeShape, 
    color, 
    bgColor, 
    logo,
    frame,
    edgeShape,
    innerColor,
    outerColor,
  } = useSelector((state) => state.styling);

  // unique random short url from api
  const {short:defaultShort, shortStatus, addStatus, addError, updateStatus, updateError, lastAddId} = useSelector(state => state.codes)

  const {targetURL, targetType, displayName} = useSelector(state => state.creationFlow) 

  const [isOpen, setIsOpen] = useState(false)
  const [warningMessage, setWarningMessage] = useState(null)

  const dispatch = useDispatch()

  const [short, setShort] = useState('');
  const [codeOptions, setCodeOptions] = useState(null);

  const ref = useRef(null);
  useEffect(() => {
    qrCode.append(ref.current);
    // if(shortStatus === IDLE) {
      dispatch(resetStyling())
      dispatch(getShort())
    // }
  }, [dispatch]);

  useEffect(() => {
    qrCode.update({
      data: baseURL+short,
    });
  }, [short]);

  useEffect(()=>{
    if(addStatus == FAILED){
      setIsOpen(true)
      setWarningMessage(addError)
    }
  }, [addStatus])

  useEffect(()=>{
    if(updateStatus == FAILED){
      setIsOpen(true)
      setWarningMessage(updateError+". Please enter another one.")
    }
  }, [updateStatus])

  useEffect(() => {
    if(shortStatus === SUCCEEDED)
      setShort(defaultShort)
  }, [defaultShort, shortStatus]);

  useEffect(() => {
    qrCode.update({
      dotsOptions: {
        color: color,
      },
    });
  }, [color]);

  useEffect(() => {
    qrCode.update({
      backgroundOptions: {
        color: bgColor,
      },
    });
  }, [bgColor]);

  useEffect(() => {
    qrCode.update({
      dotsOptions: {
        type: codeShape,
      },
    });
  }, [codeShape]);

  // edges
  useEffect(() => {
    qrCode.update({
      cornersSquareOptions: {
        color: outerColor,
      },
    });
  }, [outerColor]);

  useEffect(() => {
    qrCode.update({
      cornersDotOptions: {
        color: innerColor,
      },
    });
  }, [innerColor]);

  useEffect(() => {
    const cornersSquare = cornersOptions[edgeShape[0]];
    const cornersDot = cornersOptions[edgeShape[1]];

    qrCode.update({
      cornersSquareOptions: {
        type: cornersSquare,
      },
      cornersDotOptions: {
        type: cornersDot,
      },
    });
  }, [edgeShape]);

  const handleDownload = () => {
    //save on download
    handleSave()

    //then download
    if(!short || short.length < 3)
      return
    const options = qrCode._options//JSON.stringify(qrCode._options)
    dispatch(download({options, frame,filename:"UnkodeMe.png"}))
  }

  const handleSave = () => {
    if(short === "")
      return
    const data = {
      displayName: displayName,
      type:targetType, 
      data:baseURL+short,
      target: targetURL,
      short:short, 
      frame:frame, 
      options:JSON.stringify(qrCode._options)
    }
    if(!lastAddId){
      data["folder"]=null
      data["dynamic"]=true
      data["status"]="active"
      // if PDF use uploadFile
      if(file){
        dispatch(uploadFile({file, data}))
      }else{
        dispatch(addCode(data))
      }
    }else{
      data["id"]=lastAddId
      dispatch(updateCode(data))
    }
  }

  const scrollTop = (e) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  const scrollBottom = (e) => {
    window.scrollTo({top: document.body.offsetHeight, behavior: 'smooth'});
  }

  // <LogoSelector />;
  return (
    <div>
      <WarningModal open={isOpen} onClose={()=>setIsOpen(false)}>{warningMessage}</WarningModal>
      <div className="flex flex-col-reverse md:flex-col lg:flex-row lg:mt-0 ">
        
        {/* Settings */}
        <div className="lg:max-w-[576px] z-10">
            <UrlInput baseURL={baseURL} setShort={setShort} short={short}/>
            <FrameSelector />
            <StyleSelector />
            <SimpleEdgeSelector 
                label="Edges"
                shape={edgeShape}
                innerColor={innerColor}
                outerColor={outerColor}
                setShape={setEdgeShape}
                setInnerColor={setInnerColor}
                setOuterColor={setOuterColor}/>
        </div>
        <div className="md:hidden mt-4 bg-light dark:bg-dark py-2 sticky top-0 z-20 border-t"
            onClick={scrollBottom}>
            <h2 className="title-4">Settings</h2>
        </div>
        {/* Preview */}
        <div className="w-full my-auto md:-mt-10 z-0 md:pb-20">
          <div className='w-full relative min-h-[500px]'>
            {/* Frame */}
            <div className='absolute w-80 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-0'>
              <img className="w-full" alt='' src={frames[frame]} />
            </div>
            {/* QR Code */}
            <div
              className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-10
               p-3 rounded-lg'
              style={{ background: bgColor }}
              ref={ref}/>
            </div>
            {/* Save button */}
            <div className="flex justify-center -mt-8">
              <button
                    className="rounded-l-full z-10 py-2 pl-5 pr-2 text-light dark:text-dark text-l bg-success"
                    onClick={handleSave}
                >Save
              </button>
              <button
                  className="rounded-r-full z-10 py-2 pl-2 pr-5 text-light dark:text-dark text-l bg-brand hover:bg-interactive dark:bg-brand-dark dark:hover:bg-interactive-dark"
                  onClick={handleDownload}
                >Download
              </button>
              {/* <button
                  className="flex items-center rounded-r-full z-10 py-2 pr-2 pl-2 w-auto border-l border-interactive text-light text-l bg-brand hover:bg-interactive"
                  // onClick={handleDownload}
                >
                  <span className="mr-1">PNG</span>
                  <DownIcon/>
              </button> */}
            </div>
        </div>
        <div className="md:hidden bg-light dark:bg-dark py-2 sticky top-2 bottom-0 z-10"
          onClick={scrollTop}>
          <h2 className="title-4">Preview</h2>
        </div>
      </div>
    </div>
  );
}