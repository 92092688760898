import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { setId, setEditPanelOn, setEditPos } from '../features/utilsSlice'
import { download } from '../features/codesSlice';
import './css/qrCodeRow.css'
import { DeleteIcon, DownloadIcon, EditIcon, ExternalLinkIcon, OptionsIcon } from './icons';
import StatusMenu from './StatusMenu';

export default function QRCodeRow({qrCode, index, setOptionsVisible, setQrCode}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [status, setStatus] = useState("active")

    const handleOpen = (e) => {
        navigate('/code/edit', {state: qrCode})
    }

    const handleDelete = (e) => {
        e.stopPropagation(e)
        dispatch(setId(qrCode.id))
    }

    const handleEdit = (e) => {
        e.stopPropagation()
        dispatch(setEditPanelOn(true))
        dispatch(setEditPos(index))
    }

    const handleDownload = (e) => {
        e.stopPropagation()
        dispatch(download({options:JSON.parse(qrCode.options), frame:qrCode.frame, filename: `unkodeme_${qrCode.displayName}.png`}))
    }

    const openLink = (e) => {
        e.stopPropagation()
        if(qrCode.data.includes("https"))
            window.open(qrCode.data)
        else
            window.open("http://"+qrCode.data)
    }

    const handleOptions = (e) => {
        e.stopPropagation()
        setOptionsVisible(true)
        setQrCode(qrCode)
    }

    const formatULR = (url) => {
        return url.replace("https://", "")
    }
    return (
        <tr className='bg-light dark:bg-dark hover:bg-hover dark:hover:bg-hover-dark flex flex-col flex-no wrap sm:table-row p-5 md:p-0'
            onClick={handleOpen}>
            <td className="td relative -order-2 sm:order-none">
                <span className="title-2 md:text-body hover:text-interactive dark:hover:text-interactive-dark w-fit max-w-[74vw] md:max-w-[200px] truncate  cursor-pointer"
                    onClick={handleOpen}>
                    {qrCode.displayName}
                </span>
                <OptionsIcon className="ic-xl p-2 ic-color hover:text-interactive dark:hover:text-interactive-dark sm:hidden absolute top-0 right-0"
                    onClick={handleOptions}/>
            
            </td>
            <td className="td">
                <span className="text-body">
                    {new Date(qrCode.createdAt).toLocaleString().substring(0,9)}
                </span>
            </td>
            {/* <td className="td">
                <span className="tag">{qrCode.folder||''}</span>
            </td> */}
            <td className="td -order-1 sm:order-none">
                <div className="group flex cursor-pointer 
                    text-body text-interactive 
                    md:text-default dark:md:text-default-dark 
                    hover:text-interactive
                    dark:hover:text-interactive-dark"
                    onClick={openLink}>
                    <span className=' max-w-[74vw] md:max-w-[285px] truncate'>{formatULR(qrCode.data)}</span>
                    <ExternalLinkIcon className="ic-s ml-1 my-0 md:invisible group-hover:visible"/>
                </div>
               
            </td>
            <td className="td">
                <div className="text-body">{qrCode.nbScans}<span className='md:hidden'> scans</span></div>
            </td>
            <td className="td mt-2 md:mt-0">
                <span
                    className={
                        qrCode.status === "draft"?"draft-wrap":(qrCode.status==="disabled"?"disabled-wrap":"active-wrap")
                        }>
                    <span aria-hidden
                        className={
                            qrCode.status === "draft"?"draft":(qrCode.status==="disabled"?"disabled":"active")
                        }></span>
                    <span className="relative">{qrCode.status}</span>
                </span>
            </td>
            <td className="py-3 px-6 text-center hidden md:table-cell">
                <div className="flex item-center justify-center">
                    <div className="download"
                        onClick={handleDownload}>
                        <DownloadIcon/>
                    </div>
                    <div className="edit"
                        onClick={handleEdit}>
                        <EditIcon/>
                    </div>
                    <div className="delete"
                        onClick={handleDelete}>
                        <DeleteIcon/>
                    </div>
                </div>
            </td>
        </tr>
    )
}


