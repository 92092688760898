import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { initDeleteStatus } from '../../features/usersSlice'
import { deleteUser } from '../../features/usersSlice'
import { fetchUsers } from '../../features/usersSlice'
import { IDLE, SUCCEEDED } from '../../status'
import DeleteConfirmation from '../DeleteConfirmation'
import UserRow from './UserRow'

export default function UsersDashboard() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {users, fetchStatus, fetchError, deleteStatus, deleteError} = useSelector(state => state.users)

  const [modalVisible, setModalVisible] = useState(false)
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    if(fetchStatus === IDLE) dispatch(fetchUsers())
  }, [])

  useEffect(() => {
    if(deleteStatus === SUCCEEDED)
      setTimeout(() => {
        setModalVisible(false)
        dispatch(initDeleteStatus())
      }, 1000)
  }, [deleteStatus])

  useEffect(() => {
    if(!modalVisible)
      setUserId(null)
  }, [modalVisible])

  const openAddUser = () => {
    navigate('/users/new')   
  }

  const handleDelete = (id) => {
    setModalVisible(true)
    setUserId(id)
  }

  const confirmDelete = () => {
    if(userId !== null)
      dispatch(deleteUser(userId))
  }


  return (
   <div>
     <div className='mt-4 ml-auto right-0 rounded w-fit px-3 py-2 bg-green-500 text-white 
      hover:bg-green-600 hover:cursor-pointer'
      onClick={openAddUser}>Ajouter un utilisateur</div>
      <div className="shadow rounded-lg my-4 overflow-x-auto">
        <div className="min-w-full">
          <table className='min-w-full leading-normal '>
            <thead className='thead md:table-header-group hidden'>
            <tr>
              <th className='thead-th'>id</th>
              <th className='thead-th'>Prénom</th>
              <th className='thead-th'>Nom</th>
              <th className='thead-th'>E-mail</th>
              <th className='thead-th'>Offre</th>
            </tr>
            </thead>
            <tbody className='divide-y divide-subtil'>
              {fetchStatus === SUCCEEDED && users &&
                  users.map((user, index) => {
                    return <UserRow user={user} key={index} index={index} handleDelete={handleDelete}/>
                  })
              }
            </tbody>
        
          </table>
        </div>
      </div>

      <DeleteConfirmation 
        modalVisible={modalVisible}  
        setModalVisible={setModalVisible} 
        confirmDelete={confirmDelete}
        status={deleteStatus}
        successMessage='Utilisateur supprimé!'
        title={'Supprimer utilisateur?'}
        message={'Etes-vous sûr de vouloir supprimer cet utilisateur? Cette action est irréversible.'}/>
   </div>
  )
}
