import React, { useState } from "react";

const SHORT_REGEX =/^[a-z0-9-]{3,32}$/;
const SHORT_REGEX_ =/^[a-z0-9-]{0,32}$/;

export default function UrlInput({ title, baseURL, setShort, short, style }) {
  const [error, setError] = useState(false)

  const handleChange = (e) => {
    let value = e.target.value
    if(value.indexOf(baseURL) != 0)
      setShort("");
    else{
      let inputShort = value.replace(baseURL, '')
      if(inputShort.match(SHORT_REGEX_)){
        setShort(inputShort);
        setError(false)
        setError(!inputShort.match(SHORT_REGEX))
      }
      else 
        setError(true)

    }

  };

  return (
    <div className="block">
      <div className={`py-1 ${style?style:'mt-4 text-xl'} title-3`}>{title || 'URL'}</div>
      <input
        className="input input--md"
        value={baseURL+short}
        onChange={handleChange}
      />
      {error &&
          <label className='block text-s bg-light dark:bg-dark md:w-96 px-3 py-2 text-error dark:text-error-dark'>
            Use 3 to 32 characters (a-z, 0-9 and '-')
          </label>
      }
    </div>
  );
}
