import frame1 from "../../images/frame-1.svg";
import frame2 from "../../images/frame-2.svg";
import frame3 from "../../images/frame-3.svg";
import frame4 from "../../images/frame-4.svg";
import frame5 from "../../images/frame-5.svg";
import frame6 from "../../images/frame-6.svg";
import frame7 from "../../images/frame-7.svg";

const frames = {
  0: "none",
  1: frame1,
  2: frame2,
  3: frame3,
  4: frame4,
  5: frame5,
  6: frame6,
  7: frame7,
};

export const fr = { frame1, frame2, frame3, frame4, frame5, frame6, frame7 };

export default frames;
