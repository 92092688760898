import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { download } from '../features/codesSlice'
import { setId } from '../features/utilsSlice'
import { DeleteIcon, DownloadIcon, EditIcon } from './icons'

export default function OptionsSheet({qrCode, setOptionsVisible}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleParent = () => {
    setOptionsVisible(false)
  }

  const preventProp = (e) => {
    e.stopPropagation(e)
  }

  const handleDelete = (e) => {
    e.stopPropagation(e)
    setOptionsVisible(false)
    dispatch(setId(qrCode.id))
  }
  const handleDownload = (e) => {
    e.stopPropagation(e)
    dispatch(download({options:JSON.parse(qrCode.options), frame:qrCode.frame, filename: `unkodeme_${qrCode.displayName}.png`}))
  }
  const handleEdit = (e) => {
    e.stopPropagation(e)
    navigate('/code/edit', {state: qrCode})
  }
  return (
    <div className={`absolute z-50 inset-0 overflow-y-auto`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
        <div className="fixed inset-0 bg-scrim transition-opacity" aria-hidden="true"
          onClick={handleParent}>
          <div className='bg-light h-fit w-full absolute bottom-0 rounded-t-2xl'
            onClick={preventProp}>
            <div className="flex flex-col pt-3 pb-16">
              <div className='text-2xl font-medium text-center mb-3'>{qrCode && qrCode.displayName}</div>
              <div className='text-l active:bg-subtil p-3' onClick={handleDownload}>
                <DownloadIcon className="inline w-6 h-6 mx-3"/>Télécharger
              </div>
              <div className='text-l active:bg-subtil p-3' onClick={handleEdit}>
                <EditIcon className="inline w-6 h-6 mx-3"/>Modifier
              </div>
              <div className='text-l active:bg-subtil p-3' onClick={handleDelete}>
                <DeleteIcon className="inline w-6 h-6 mx-3"/>Supprimer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
