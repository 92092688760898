import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BreadcrumbNav from '../components/BreadcrumbNav'
import ChooseQRType from '../components/ChooseQRType'

import './css/createQR.css'
import DesignContent from './DesignContent'
import DesignQRCode from './DesignQRCode'

import {init as initCode} from '../features/codesSlice'
import { init as initFlow } from '../features/creationFlowSlice'
import { setToolbarTitle } from '../features/utilsSlice'
import UploadFile from '../components/UploadFile'

import { WEBSITE, PDF } from '../features/targetTypes'
import Website from '../components/Website'

export default function CreateQR() {
    const dispatch = useDispatch()
    const {currentTab, targetType} = useSelector(state => state.creationFlow)

    const [file, setFile] = useState(null)

    useEffect(()=>{
        dispatch(initFlow({
            currentTab: 1, // 1:type | 2:content | 3:QRCode
            targetType: null,
            targetURL: "",
            displayName: ""
        }))
        dispatch(initCode())
        dispatch(setToolbarTitle("Create QR Code"))
    }, [])

    const destinations = {
        WEBSITE: <Website/>,
        PDF: <UploadFile file={file} setFile={setFile}/>
    }
    return (
        <div className='pt-6'>
            <header className="container bg-light dark:bg-dark md:flex pb-1 ">
                {/* <div>
                    <h2 className="text-xl">Create QR Code</h2> */}
                    <BreadcrumbNav />
                {/* </div> */}
                {/* {currentTab===3 && <ul className='flex flex-1 justify-end md:pr-24 pt-1'>
                    <button className='w-24 h-9 text-gray-600 border border-gray-300 hover:bg-blue-300 
                        hover:border-blue-300 hover:text-white rounded mr-2'>Download</button>
                    <button className='w-24 h-9 text-white bg-green-400 hover:bg-green-500 rounded '>Save</button>
                </ul>} */}
            </header>
            <section>
                <div className="container">
                    {/* <UploadFile/> */}
                    {currentTab===1 && <ChooseQRType />}
                    {currentTab===2 && destinations[targetType]}
                    {currentTab===3 && <DesignQRCode file={file}/>}
                </div>
            </section>
            
        </div>
    )
}
