import React from 'react'

export default function ComingSoon() {
    return (
        <div className=" bg-light dark:bg-dark h-screen flex items-center justify-center">
            <div className="text-center">
            <h1 className="font-extrabold text-gray-800 text-6xl xs:text-6xl md:text-8xl">Coming Soon.</h1>
            <h2 className="font-bold text-gray-800 mt-2 text-md xs:text-xl md:text-2xl leading-tight">We are working hard to get this up and running.</h2>
            </div>        
        </div>
    )
}
