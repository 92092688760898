import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RadioGroup } from "@headlessui/react";

import roundedQR from "../../images/rounded-2.png";
import dotsQR from "../../images/dots.png";
import extraRoundedQR from "../../images/rounded-1.png";
import classyRoundedQR from "../../images/default.png";
import squareQR from "../../images/square.png";
import classyQR from "../../images/connect-horizontal.png";
import ColorPicker from "../../components/ColorPicker";

import { setCodeShape, setColor, setBgColor } from "./stylingSlice";

// options: rounded, extra-rounded, square, dots, classy, classy-rounded
export default function StyleSelector() {
  const dispatch = useDispatch();

  const { codeShape, color, bgColor } = useSelector(
    (state) => state.styling
  );

  const handleSelection = (shape) => {
    dispatch(setCodeShape(shape));
  };

  return (
    <div className="mt-6">
      <div className="mb-4 title-3">
        Code Style
      </div>
      <div>
        <RadioGroup value={codeShape} onChange={handleSelection}>
          <div className="flex overflow-x-auto">
            <RadioGroup.Option className="inline-block m-1" value={"rounded"}>
              {({ checked }) => (
                <button
                  className={
                    "p-2.5 mx-1 hover:bg-hover-secondary dark:bg-hover-secondary-dark:hover:bg-hover-secondary-dark rounded w-20 h-20 " +
                    (checked || codeShape==="rounded"
                      ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive dark:border-item-interactive-dark"
                      : "bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img src={roundedQR} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option
              className="inline-block m-1"
              value={"extra-rounded"}
            >
              {({ checked }) => (
                <button
                  className={
                    "p-2.5 mx-1 hover:bg-hover-secondary dark:bg-hover-secondary-dark:hover:bg-hover-secondary-dark rounded w-20 h-20 " +
                    (checked || codeShape==="extra-rounded"
                      ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive dark:border-item-interactive-dark"
                      : "bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img src={extraRoundedQR} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"square"}>
              {({ checked }) => (
                <button
                  className={
                    "p-2.5 mx-1 hover:bg-hover-secondary dark:bg-hover-secondary-dark:hover:bg-hover-secondary-dark rounded w-20 h-20 " +
                    (checked || codeShape==="square"
                      ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive dark:border-item-interactive-dark"
                      : "bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img src={squareQR} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"dots"}>
              {({ checked }) => (
                <button
                  className={
                    "p-2.5 mx-1 hover:bg-hover-secondary dark:bg-hover-secondary-dark:hover:bg-hover-secondary-dark rounded w-20 h-20 " +
                    (checked || codeShape==="dots"
                      ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive dark:border-item-interactive-dark"
                      : "bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img src={dotsQR} />
                </button>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option className="inline-block m-1" value={"classy"}>
              {({ checked }) => (
                <button
                  className={
                    "p-2.5 mx-1 hover:bg-hover-secondary dark:bg-hover-secondary-dark:hover:bg-hover-secondary-dark rounded w-20 h-20 " +
                    (checked || codeShape==="classy"
                      ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive dark:border-item-interactive-dark"
                      : "bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img src={classyQR} />
                </button>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              className="inline-block m-1"
              value={"classy-rounded"}
            >
              {({ checked }) => (
                <button
                  className={
                    "p-2.5 mx-1 hover:bg-hover-secondary dark:bg-hover-secondary-dark:hover:bg-hover-secondary-dark rounded w-20 h-20 " +
                    (checked || codeShape==="classy-rounded"
                      ? "bg-hover-secondary dark:bg-hover-secondary-dark border border-item-interactive dark:border-item-interactive-dark"
                      : "bg-subtler dark:bg-subtler-dark")
                  }
                >
                  <img src={classyRoundedQR} />
                </button>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>

      <div className="flex ml-16">
        <div className="mt-4">
          <ColorPicker
            label="Code color"
            color={color}
            setColor={setColor}
          />
        </div>
        <div className="mt-4">
          <ColorPicker
            adapt={true}
            label="Background color"
            color={bgColor}
            setColor={setBgColor}
          />
        </div>
      </div>
    </div>
  );
}
