import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {logout} from '../features/user/authSlice'

import './css/sidebar.css'
import { CollectionIcon } from "./icons";
import {IoPeopleOutline as UsersIcon} from 'react-icons/io5'

// import { QrCodeOutline } from "react-ionicons";
// import { AnalyticsOutline } from "react-ionicons";
// import { SettingsOutline } from "react-ionicons";
// import { CaretForwardOutline } from "react-ionicons";
// import { AddOutline } from "react-ionicons";
export default function Sidebar() {
  const [active, setActive] = useState(1)

  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <div>
      <aside className="menu bg-light dark:bg-dark pt-2 invisible md:visible z-40">
      <ul className="menu-nav">
        <li className="logo">
          <a href="#" className="menu-link">
            <span className="expanded text-xl">UnkodeMe</span>
            <span className="collapsed">UM</span>
            {/* <CaretForwardOutline
              color={icon_color}
              height={icon_size}
              width={icon_size}
            /> */}
          </a>
        </li>
        <li className="menu-item" onClick={()=> setActive(1)}>
          <Link to="/myqrs" className={`menu-link ${active===1?'selected':'default'}`}>
            {/* <QrCodeOutline
              color={icon_color}
              height={icon_size}
              width={icon_size}
            /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nav-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
              />
            </svg>
            <span className="link-text">Mes QR</span>
          </Link>
        </li>
        <li className="menu-item" onClick={()=> setActive(2)}>
          <Link to="/analysis" className={`menu-link ${active===2?'selected':'default'}`}>
            {/* <AnalyticsOutline
              color={icon_color}
              height={icon_size}
              width={icon_size}
            /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nav-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
              />
            </svg>
            <span className="link-text">Analyses</span>
          </Link>
        </li>
        <li className="menu-item" onClick={()=> setActive(3)}>
          <Link to="/newqr" className={`menu-link ${active===3?'selected':'default'}`}>
            {/* <AddOutline
              color={icon_color}
              height={icon_size}
              width={icon_size}
            /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nav-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span className="link-text">Créer QR</span>
          </Link>
        </li>
        <li className="menu-item" onClick={()=> setActive(4)}>
          <Link to="/fidelity" className={`menu-link ${active===4?'selected':'default'}`}>
            <CollectionIcon className="nav-icon"/>
            <span className="link-text">Fidélité</span>
          </Link>
        </li>
        <li className="menu-item" onClick={()=> setActive(5)}>
          <Link to="/users" className={`menu-link ${active===5?'selected':'default'}`}>
            <UsersIcon className="nav-icon"/>
            <span className="link-text">Users</span>
          </Link>
        </li>
        <li className="menu-item" onClick={handleLogout}>
          <span className="menu-link default">
            {/* <SettingsOutline
              color={icon_color}
              height={icon_size}
              width={icon_size}
            /> */}
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="nav-icon"
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor">
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span className="link-text">Logout</span>
          </span>
        </li>
        <li className="menu-item">
          <Link to="/settings" className="menu-link default">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nav-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span className="link-text">Paramètres</span>
          </Link>
        </li>
      </ul>
    </aside>
    </div>
  );
}
