import QRCodeStyling from 'qr-code-styling';
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import StatusMenu from './StatusMenu';
import UrlInput from './UrlInput'
import TargetURLInput from './TargetURLInput'

import frames, {fr} from "../features/styling/frames";
import { LOADING, SUCCEEDED } from '../status';
import { updateCode } from '../features/codesSlice';
import { setEditPanelOn } from '../features/utilsSlice';
import { CloseIcon } from './icons';

export default function EditQRPanel({qrCode}) {
    const baseURL = "unkode.me/qr/"
    const updateStatus = useSelector(state => state.codes.updateStatus)
    const [isDirty, setIsDirty] = useState(false)
    const [urlEditabled, setUrlEditabled] = useState(false)
    
    const [didMount, setDidMount] = useState(false)
    const [short, setShort] = useState(qrCode.short)
    const [target, setTarget] = useState(qrCode.target)
    const [displayName, setDisplayName] = useState(qrCode.displayName)
    const [status, setStatus] = useState(qrCode.status)
    const [data, setData] = useState(qrCode.data)

    const dispatch = useDispatch()
    
    const size = 160;
    const options = qrCode.options?JSON.parse(qrCode.options): null
    options.width = size
    options.height = size
    const qrCodeStyling = new QRCodeStyling(options);
    const qrDivRef = useRef(null);
    

    useEffect(()=>{
        qrCodeStyling.append(qrDivRef.current)
    }, [dispatch])

    /**
     * If anaything changes => set isDirty to true to enable save btn
     */
    useEffect(()=>{
        if(didMount && !isDirty)
            setIsDirty(true)
        
        setDidMount(true)
    }, [displayName, status, target])


    // close panel if update succeeds
    useEffect(()=>{
        if(isDirty && updateStatus===SUCCEEDED)
            handleClose()
    }, [updateStatus])

    const handleClose = (e) => {
        dispatch(setEditPanelOn(false))
    }

    const handleCloseParent = (e) => {
        if(e.target !== e.currentTarget)
            return
        handleClose(e)
    }
    const handleDisplayName = (e) => {
        let value = e.target.value
        setDisplayName(value)
    }

    const handleSave = (e) => {
        if(isDirty && updateStatus!==LOADING){
            dispatch(updateCode({
                id:qrCode.id, 
                displayName:displayName, 
                status:status, 
                data:data,
                target:target
            }))
        }
    }

    return (
        <div className="fixed z-50 inset-0 " 
            aria-labelledby="modal-title" 
            role="dialog" 
            aria-modal="true">
             <div className="flex min-h-screen sm:block sm:p-0">
                <div className="fixed flex flex-row-reverse inset-0 bg-scrim items-center"  aria-hidden="true"
                onClick={handleCloseParent}
                    >
                    <div className="w-[90vw] md:w-1/3 pt-4 px-6 bg-light dark:bg-dark h-full overflow-y-auto">
                        <div className="fixed inline-flex items-center top-0 w-[inherit] -ml-6 pl-6 py-5">
                            <span className='title-3'>Edit</span>
                            <CloseIcon
                                onClick={handleClose}
                                className="ml-auto mr-6 w-5 h-5 text-subtle dark:text-subtle hover:text-default dark:hover:text-default-dark"
                                aria-hidden="true"/>
                        </div>

                        <input className='input font-medium mt-14' 
                            type="text" 
                            value={displayName||''}
                            onChange={handleDisplayName} />
                        
                        <div className='mt-2 mb-2'>
                            <div className="title-3 mt-6"
                            >Status</div>
                            <StatusMenu status={status} setStatus={setStatus}/>
                        </div>
                        {urlEditabled?(
                            <UrlInput title={'QR Code Data'} baseURL={baseURL} setShort={setShort} short={short} style={'mb-2 text-sm'}/>
                        ):(   
                            <div className="block">
                                <div className='title-3 mt-6'
                                    >QR Code Data</div>
                                <div
                                    className="py-2 pl-3 pr-4 w-full md:w-96"
                                    >{baseURL+short}
                                </div>
                            </div>
                        )}
                        {qrCode.type === 'WEBSITE' && data && <TargetURLInput title={'Target URL'} data={target} setData={setTarget}/>}
               
                        {/* <div className='mt-2'>
                            <div className="title-3 mt-6"
                            >Mots-clés</div>
                            <Tags tags={tags}/>
                        </div> */}

                        <div className='mt-2'>
                            <div className="title-3 mt-6"
                            >QR Code</div>
                            <QRPreview qrDivRef={qrDivRef} frame={frames[qrCode.frame]} codeBgColor={"#FFFFFF"}/>
                        </div>

                        <div className="fixed flex flex-row-reverse w-[inherit] bg-light dark:bg-dark
                            bottom-0 py-4 px-6 -ml-6 border-t border-subtler z-20">
                            <button className={`${updateStatus===LOADING?'animate-pulse':''} w-full rounded px-10 py-2
                                text-white uppercase
                                bg-success
                                disbaled:cursor-default
                                disabled:bg-subtle`}
                                disabled={!isDirty}
                                onClick={handleSave}
                            >{updateStatus===LOADING?'Loading':'Save'}</button>
                            
                        </div>
                    </div>                    
                </div>
            </div>
            
        </div>
    )
}

const QRPreview = ({qrDivRef, frame, codeBgColor}) => {
    return (
        <div className="mb-28">
          <div className='relative h-[300px] rounded-xl'>
            <div className='w-60 md:top-[50%] top-[35%] absolute left-[50%]  translate-x-[-50%] translate-y-[-50%]'>
              <img className="w-80 z-0" alt='' src={frame} />
            </div>
            <div
              className='p-3 z-10 rounded-lg md:top-[50%] top-[35%] absolute left-[50%]  translate-x-[-50%] translate-y-[-50%]'
              style={{ background: codeBgColor }}
              ref={qrDivRef}/>
            </div>
            {/* <div className="flex mt-8">
              <button
                  className="rounded py-1 border border-blue-500 text-blue-500 text-lg bg-light dark:bg-dark hover:text-white hover:bg-blue-500 w-28"
                  onClick={handleSave}
                >Save
              </button>

              <button
                  className="rounded py-1 ml-1 text-white text-lg bg-blue-400 hover:bg-blue-500 w-28"
                  onClick={handleDownload}
                >Download
              </button>
            </div> */}
        </div>
    )
}
