import { createSlice } from "@reduxjs/toolkit";

const creationFlowSlice = createSlice({
    name: "creationFlow",
    initialState: {
        currentTab: 1, // 1:type | 2:content | 3:QRCode
        targetType: null,
        targetURL: "",
        displayName: "",
        file: null
    },
    reducers: {
        init: (state, action) => {
            state.currentTab = action.payload.currentTab
            state.targetType = action.payload.targetType
            state.targetURL = action.payload.targetURL
            state.displayName = action.payload.displayName
            state.file = action.payload.file
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload
        },
        setTargetType:(state, action)=>{
            state.targetType = action.payload
        },
        setTargetURL:(state, action)=>{
            state.targetURL = action.payload
        },
        setDisplayName:(state, action)=>{
            state.displayName = action.payload
        },
        setFile:(state, action) => {
            state.file = action.file
        }
    }
})


export const { setCurrentTab, setTargetType, setTargetURL, setDisplayName, init, setFile } = creationFlowSlice.actions

export default creationFlowSlice.reducer