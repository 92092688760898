import { current } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentTab } from '../features/creationFlowSlice'

export default function BreadcrumbNav() {
    const dispatch = useDispatch()
    const {currentTab} = useSelector(state => state.creationFlow)
    const switchTab = (tab) => {
        if(tab < currentTab)
            dispatch(setCurrentTab(tab))
    }
    // [#4D5EC0]

    return (
        <div>
            <ol className='flex text-s space-x-1 md:space-x-2 overflow-x-auto crumbs'>
                <li className={`${currentTab===1?'cursor-default':' text-interactive dark:text-interactive-dark cursor-pointer'}`}>
                    <div >
                        <span 
                            onClick={() => switchTab(1)}>Type</span>
                    </div>
                </li>
                {currentTab >= 2 &&
                    <li className={`${currentTab===2?'cursor-default':(currentTab>2?' text-interactive dark:text-interactive-dark cursor-pointer':'text-subtle cursor-default')}`}>
                        <div >
                            <span className='mr-2 font-medium'>/</span>
                            <span
                                onClick={() => switchTab(2)}>Content Design</span>
                        </div>
                    </li>
                    
                }
                {currentTab === 3 &&
                    <li className={`${currentTab===3?'cursor-default':'text-subtle cursor-default'}`}>
                        <div>
                            <span className='mr-2 font-medium'>/</span>
                            <span
                                onClick={() => switchTab(3)}>QRCode Design</span>
                        </div>
                    </li>
                }
            </ol>
        </div>
    )
}
